import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks/hooks';

import { MainHeader } from '../../components/mainHeader/MainHeader';
import { PageTopic } from '../../components/pageTopic/PageTopic';
import { TableContent } from '../../components/table/TableContent';
import { EmptyContent } from '../../components/emptyContent/EmptyContent';
import { CommonAlert } from '../../components/atoms/Alert';
import { Pagination } from '../../components/pagination/Pagination';
import { Spinner } from '../../components/atoms/Spinner';
import { BillingSnapshotReportAdvanceSearch } from '../../components/tableSearch/BillingSnapshotReportAdvanceSearch';

import {
  getBillingSnapshotReportRequest,
  getBillingSnapshotReportSelector,
  clearBillingSnapshotReportResponse,
} from '../../redux/slices/reports/getBillingSnapshotReportSlice';
import {
  getBillingSnapshotSummaryRequest,
  getBillingSnapshotSummarySelector,
  clearFetchBillingSnapshotSummaryResponse,
} from '../../redux/slices/reports/getBillingSnapshotSummarySlice';
import {
  getSystemProvidersRequest,
  getSystemProvidersSelector,
} from '../../redux/slices/system/getSystemProvidersSlice';

import { formatCurrency } from '../../utils/commonFunctions';

const columns = [
  {
    title: 'Total Charges',
    dataIndex: 'totalCharges',
    key: 'totalCharges',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Billed Charges',
    dataIndex: 'billedCharges',
    key: 'billedCharges',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Payments',
    dataIndex: 'payments',
    key: 'payments',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Adjustment',
    dataIndex: 'adjustment',
    key: 'adjustment',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Billed Outstanding',
    dataIndex: 'billedOutstanding',
    key: 'billedOutstanding',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Unbilled Outstanding',
    dataIndex: 'unbilledOutstanding',
    key: 'unbilledOutstanding',
    render: (text: number) => formatCurrency(text ?? 0),
  },
];

const dummyDataSource = [
  {
    totalCharges: 682.0,
    billedCharges: 256.32,
    payments: 171.68,
    adjustment: 254.0,
    billedOutstanding: 0.0,
    unbilledOutstanding: 1213,
  },
];

const dummySummaryDataSource = {
  primaryBilledOutstanding: 891.0,
  unbilledOutstandingExcludingCancelled: 129.21,
};

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  {
    id: 'billingSnapshot',
    label: 'Billing Snapshot',
    status: 'active',
    link: 'billing-snapshot',
  },
];

export const BillingSnapshotReportPage = () => {
  const dispatch = useAppDispatch();

  const { fetchBillingSnapshotReportData, fetchBillingSnapshotReportStatus, fetchBillingSnapshotReportLoading } =
    useAppSelector(getBillingSnapshotReportSelector);
  const { fetchBillingSnapshotSummaryData, fetchBillingSnapshotSummaryStatus } = useAppSelector(
    getBillingSnapshotSummarySelector
  );
  const { systemProvidersProviderData } = useAppSelector(getSystemProvidersSelector);

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [summaryDataSource, setSummaryDataSource] = useState<any>({});
  const [filterObject, setFilterObject] = useState<any>({ filterBy: 'CHARGE_DATE_CREATED' });

  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });

  useEffect(() => {
    if (systemProvidersProviderData?.length === 0) {
      fetchSystemProviders();
    }
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  }, []);

  useEffect(() => {
    if (filterObject) {
      fetchBillingSnapshotReport();
      fetchBillingSnapshotSummary();
    }
  }, [filterObject]);

  useEffect(() => {
    if (fetchBillingSnapshotReportStatus === 'SUCCESS') {
      setDataSource([fetchBillingSnapshotReportData]);
      dispatch(clearBillingSnapshotReportResponse());
    } else if (fetchBillingSnapshotReportStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearBillingSnapshotReportResponse());
    }
  }, [fetchBillingSnapshotReportStatus]);

  useEffect(() => {
    if (fetchBillingSnapshotSummaryStatus === 'SUCCESS') {
      setSummaryDataSource(fetchBillingSnapshotSummaryData);
      dispatch(clearFetchBillingSnapshotSummaryResponse());
    } else if (fetchBillingSnapshotSummaryStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong when fetching summary!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearFetchBillingSnapshotSummaryResponse());
    }
  }, [fetchBillingSnapshotSummaryStatus]);

  const fetchBillingSnapshotReport = async () => {
    dispatch(getBillingSnapshotReportRequest({ filters: filterObject }));
  };

  const fetchBillingSnapshotSummary = async () => {
    dispatch(getBillingSnapshotSummaryRequest({ filters: filterObject }));
  };

  const fetchSystemProviders = () => {
    dispatch(getSystemProvidersRequest());
  };

  const handleSearch = (data: any) => {
    const updatedData = { ...data };

    if (!updatedData?.providerId || updatedData?.providerId === '') {
      delete updatedData.providerId;
    } else {
      updatedData.providerId = parseInt(updatedData?.providerId, 10);
    }

    if (!updatedData?.type || updatedData?.type === '') {
      delete updatedData.type;
    }

    setFilterObject(updatedData);
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic mainTitle="Billing Snapshot" enablePrimaryButton={false} breadCrumbArr={breadCrumbArr} />

      <BillingSnapshotReportAdvanceSearch onSubmit={handleSearch} />

      {visibleAlert && alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}

      {fetchBillingSnapshotReportLoading ? (
        <Spinner />
      ) : (
        <>
          {dummyDataSource?.length > 0 ? (
            <>
              <div className="flex justify-end mb-4">
                <div className="flex items-center">
                  <label htmlFor="search" className="mr-2 text-gray-600">
                    Search:
                  </label>
                  <input
                    type="text"
                    className="border border-gray-300 rounded px-2 py-1"
                    name="search"
                    title="Search"
                    disabled
                  />
                </div>
              </div>

              <TableContent columns={columns} dataSource={dummyDataSource} enableActions={false} />

              <table className="border border-gray-100 w-full text-center mt-6">
                <thead>
                  <tr className="bg-gray-100 text-gray7">
                    <th className="border border-gray-200 p-2">Primary Billed Outstanding</th>
                    <th className="border border-gray-200 p-2">Unbilled Outstanding Excluding Cancelled</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border border-gray-200 p-2">
                      {formatCurrency(dummySummaryDataSource.primaryBilledOutstanding ?? 0)}
                    </td>
                    <td className="border border-gray-200 p-2">
                      {formatCurrency(dummySummaryDataSource.unbilledOutstandingExcludingCancelled ?? 0)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          ) : (
            <EmptyContent mode="VIEW" enableCreateButton={false} />
          )}
        </>
      )}
    </div>
  );
};

import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks/hooks';
import moment from 'moment';

import { MainHeader } from '../../components/mainHeader/MainHeader';
import { PageTopic } from '../../components/pageTopic/PageTopic';
import { TableContent } from '../../components/table/TableContent';
import { EmptyContent } from '../../components/emptyContent/EmptyContent';
import { Pagination } from '../../components/pagination/Pagination';
import { CommonAlert } from '../../components/atoms/Alert';
import { Spinner } from '../../components/atoms/Spinner';
import { ARAnalysisAdvanceSearch } from '../../components/tableSearch/ARAnalysisAdvanceSearch';

import {
  getArAnalysisRequest,
  getArAnalysisSelector,
  clearFetchArAnalysisResponse,
} from '../../redux/slices/reports/getArAnalysisSlice';
import { getSystemProvidersRequest } from '../../redux/slices/system/getSystemProvidersSlice';
import { getSystemFacilityRequest } from '../../redux/slices/system/getSystemFacilitiesSlice';

import { formatCurrency } from '../../utils/commonFunctions';

const PAGE_SIZE = 25;

const columns = [
  {
    title: 'Transaction ID',
    dataIndex: 'transactionId',
    key: 'transactionId',
  },
  {
    title: 'Patient ID',
    dataIndex: 'patientId',
    key: 'patientId',
  },
  {
    title: 'Visit ID',
    dataIndex: 'visitId',
    key: 'visitId',
  },
  {
    title: 'PCN',
    dataIndex: 'pcn',
    key: 'pcn',
  },
  {
    title: 'Visit Date',
    dataIndex: 'visitDate',
    key: 'visitDate',
    render: (text: string) => (moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : ''),
  },
  {
    title: 'Patient Name',
    dataIndex: 'patientName',
    key: 'patientName',
  },
  {
    title: 'Provider',
    dataIndex: 'provider',
    key: 'provider',
  },
  {
    title: 'Facility',
    dataIndex: 'facility',
    key: 'facility',
  },
  {
    title: 'Insurance',
    dataIndex: 'insurance',
    key: 'insurance',
  },
  {
    title: 'Policy ID',
    dataIndex: 'policyId',
    key: 'policyId',
  },
  {
    title: 'CPT Code',
    dataIndex: 'cptCode',
    key: 'cptCode',
  },
  {
    title: 'Charge',
    dataIndex: 'charge',
    key: 'charge',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Expected',
    dataIndex: 'expected',
    key: 'expected',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Payment',
    dataIndex: 'payment',
    key: 'payment',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Adjustment',
    dataIndex: 'adjustment',
    key: 'adjustment',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    key: 'balance',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Responsibility',
    dataIndex: 'responsibility',
    key: 'responsibility',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Transaction Date',
    dataIndex: 'transactionDate',
    key: 'transactionDate',
    render: (text: string) => (moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : ''),
  },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  {
    id: 'arAnalysisReport',
    label: 'AR Analysis',
    status: 'active',
    link: 'ar-analysis-report',
  },
];

const initialAlertState = {
  color: '',
  message: '',
  error: false,
};

export const ARAnalysisReportPage = () => {
  const dispatch = useAppDispatch();

  const { fetchArAnalysisStatus, fetchArAnalysisData, fetchArAnalysisLoading } = useAppSelector(getArAnalysisSelector);

  const initialFromDate = moment().startOf('month').format('YYYY-MM-DD');
  const initialToDate = moment().endOf('month').format('YYYY-MM-DD');

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [filterObject, setFilterObject] = useState<any>({
    dateFrom: initialFromDate,
    dateTo: initialToDate,
    type: 'DATE_OF_SERVICE',
  });

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>(initialAlertState);

  useEffect(() => {
    fetchSystemProviders();
    fetchSystemFacilities();
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  }, []);

  useEffect(() => {
    if (filterObject) {
      fetchARAnalysisReport();
    }
  }, [filterObject]);

  useEffect(() => {
    if (fetchArAnalysisStatus === 'SUCCESS') {
      setDataSource(fetchArAnalysisData?.items);
      setCurrentPage(fetchArAnalysisData?.currentPage);
      setTotalPages(fetchArAnalysisData?.totalPages);
      dispatch(clearFetchArAnalysisResponse());
    } else if (fetchArAnalysisStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj(initialAlertState);
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearFetchArAnalysisResponse());
    }
  }, [fetchArAnalysisStatus]);

  const onPageChange = (page: any) => {
    setCurrentPage(page - 1);
    fetchARAnalysisReport(page - 1);
  };

  const fetchARAnalysisReport = async (pageNumber = currentPage) => {
    dispatch(getArAnalysisRequest({ size: PAGE_SIZE, page: pageNumber, filters: filterObject }));
  };

  const fetchSystemProviders = () => {
    dispatch(getSystemProvidersRequest());
  };

  const fetchSystemFacilities = () => {
    dispatch(getSystemFacilityRequest());
  };

  const handleSearch = (data: any) => {
    const updatedData = { ...data };

    if (!updatedData?.providerId || updatedData?.providerId === '') {
      delete updatedData.providerId;
    } else {
      updatedData.providerId = parseInt(updatedData?.providerId, 10);
    }

    if (!updatedData?.facilityId || updatedData?.facilityId === '') {
      delete updatedData.facilityId;
    } else {
      updatedData.facilityId = parseInt(updatedData?.facilityId, 10);
    }

    if (!updatedData?.arType || updatedData?.arType === '') {
      delete updatedData.arType;
    }

    if (!updatedData?.state || updatedData?.state === '') {
      delete updatedData.state;
    }

    setCurrentPage(0);
    setFilterObject(updatedData);
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic mainTitle="AR Analysis" enablePrimaryButton={false} breadCrumbArr={breadCrumbArr} />

      <ARAnalysisAdvanceSearch onSubmit={handleSearch} />

      {visibleAlert && alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}

      {fetchArAnalysisLoading ? (
        <Spinner />
      ) : (
        <>
          {dataSource && dataSource?.length > 0 ? (
            <>
              <div className="overflow-x-auto">
                <TableContent columns={columns} dataSource={dataSource} enableActions={false} />
              </div>

              {totalPages > 1 && (
                <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
              )}
            </>
          ) : (
            <EmptyContent mode="VIEW" enableCreateButton={false} />
          )}
        </>
      )}
    </div>
  );
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  UpdateVisitEligibilityStatusInterface,
  UpdateVisitEligibilityStatusInitialInterface,
} from '../../../interfaces/patientInterface';
import { updateVisitEligibilityStatusAPIRequest } from '../../../api/patient/patientVisit';

const initialState: UpdateVisitEligibilityStatusInitialInterface = {
  editVisitEligibilityStatusLoading: false,
  editVisitEligibilityStatusData: {},
  editVisitEligibilityStatusError: null,
  editVisitEligibilityStatusStatus: 'IDLE',
};

export const updateVisitEligibilityStatusRequest: any = createAsyncThunk(
  'update/visit-eligibility-status',
  async (visitData: UpdateVisitEligibilityStatusInterface, thunkAPI: any) => {
    try {
      const response: any = await updateVisitEligibilityStatusAPIRequest(visitData);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const updateVisitEligibilityStatusSlice = createSlice({
  name: 'editVisitEligibilityStatus',
  initialState,
  reducers: {
    clearUpdateVisitEligibilityStatusResponse: (state) => {
      state.editVisitEligibilityStatusLoading = false;
      state.editVisitEligibilityStatusStatus = 'IDLE';
      state.editVisitEligibilityStatusData = {};
      state.editVisitEligibilityStatusError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateVisitEligibilityStatusRequest.pending, (state, action) => {
        state.editVisitEligibilityStatusLoading = true;
        state.editVisitEligibilityStatusStatus = 'PENDING';
      })
      .addCase(updateVisitEligibilityStatusRequest.fulfilled, (state, action) => {
        state.editVisitEligibilityStatusLoading = false;
        state.editVisitEligibilityStatusData = action.payload;
        state.editVisitEligibilityStatusStatus = 'SUCCESS';
      })
      .addCase(updateVisitEligibilityStatusRequest.rejected, (state, action) => {
        state.editVisitEligibilityStatusLoading = false;
        state.editVisitEligibilityStatusError = action.payload;
        state.editVisitEligibilityStatusStatus = 'FAILED';
      });
  },
});

export const { clearUpdateVisitEligibilityStatusResponse } = updateVisitEligibilityStatusSlice.actions;

export const updateVisitEligibilityStatusSelector = (state: RootState) => state.UpdateVisitEligibilityStatus;

export default updateVisitEligibilityStatusSlice.reducer;

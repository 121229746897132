import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  FetchPaymentAdjustmentCodeByIdInterface,
  FetchPaymentAdjustmentCodeByIdInitialInterface,
} from '../../../interfaces/codesInterfaces';
import { getPaymentAdjustmentCodeByIdAPIRequest } from '../../../api/account/paymentAdjustment';

const initialState: FetchPaymentAdjustmentCodeByIdInitialInterface = {
  fetchPaymentAdjustmentCodeByIdLoading: false,
  fetchPaymentAdjustmentCodeByIdData: {},
  fetchPaymentAdjustmentCodeByIdError: null,
  fetchPaymentAdjustmentCodeByIdStatus: 'IDLE',
};

export const getPaymentAdjustmentCodeByIdRequest: any = createAsyncThunk(
  'payment-adjustment-code/get/id',
  async (searchParams: FetchPaymentAdjustmentCodeByIdInterface, thunkAPI: any) => {
    try {
      const response: any = await getPaymentAdjustmentCodeByIdAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getPaymentAdjustmentCodeByIdSlice = createSlice({
  name: 'fetchPaymentAdjustmentCodeById',
  initialState,
  reducers: {
    clearFetchPaymentAdjustmentCodeByIdResponse: (state) => {
      state.fetchPaymentAdjustmentCodeByIdLoading = false;
      state.fetchPaymentAdjustmentCodeByIdData = {};
      state.fetchPaymentAdjustmentCodeByIdStatus = 'IDLE';
      state.fetchPaymentAdjustmentCodeByIdError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentAdjustmentCodeByIdRequest.pending, (state, action) => {
        state.fetchPaymentAdjustmentCodeByIdLoading = true;
        state.fetchPaymentAdjustmentCodeByIdStatus = 'PENDING';
      })
      .addCase(getPaymentAdjustmentCodeByIdRequest.fulfilled, (state, action) => {
        state.fetchPaymentAdjustmentCodeByIdLoading = false;
        state.fetchPaymentAdjustmentCodeByIdStatus = 'SUCCESS';
        state.fetchPaymentAdjustmentCodeByIdData = action.payload;
      })
      .addCase(getPaymentAdjustmentCodeByIdRequest.rejected, (state, action) => {
        state.fetchPaymentAdjustmentCodeByIdLoading = false;
        state.fetchPaymentAdjustmentCodeByIdStatus = 'FAILED';
        state.fetchPaymentAdjustmentCodeByIdError = action.payload;
      });
  },
});

export const { clearFetchPaymentAdjustmentCodeByIdResponse } = getPaymentAdjustmentCodeByIdSlice.actions;

export const getPaymentAdjustmentCodeByIdSelector = (state: RootState) => state.GetPaymentAdjustmentCodeById;

export default getPaymentAdjustmentCodeByIdSlice.reducer;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  FetchBankReconcilationReportInterface,
  FetchBankReconcilationReportInitialInterface,
} from '../../../interfaces/reportsInterfaces';

const initialState: FetchBankReconcilationReportInitialInterface = {
  fetchBankReconcilationReportLoading: false,
  fetchBankReconcilationReportData: {
    currentPage: 0,
    items: [],
    totalItems: 0,
    totalPages: 0,
  },
  fetchBankReconcilationReportError: null,
  fetchBankReconcilationReportStatus: 'IDLE',
};

export const getBankReconcilationReportRequest: any = createAsyncThunk(
  'reports/bank-reconcilation-report/get',
  async (reportData: FetchBankReconcilationReportInterface, thunkAPI: any) => {
    try {
      //   const response: any = await getBankReconcilationReportAPIRequest(reportData);
      //   return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getBankReconcilationReportSlice = createSlice({
  name: 'fetchBankReconcilationReport',
  initialState,
  reducers: {
    clearFetchBankReconcilationReportResponse: (state) => {
      state.fetchBankReconcilationReportLoading = false;
      state.fetchBankReconcilationReportData = { currentPage: 0, items: [], totalItems: 0, totalPages: 0 };
      state.fetchBankReconcilationReportStatus = 'IDLE';
      state.fetchBankReconcilationReportError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBankReconcilationReportRequest.pending, (state, action) => {
        state.fetchBankReconcilationReportLoading = true;
        state.fetchBankReconcilationReportStatus = 'PENDING';
      })
      .addCase(getBankReconcilationReportRequest.fulfilled, (state, action) => {
        state.fetchBankReconcilationReportLoading = false;
        state.fetchBankReconcilationReportStatus = 'SUCCESS';
        state.fetchBankReconcilationReportData = action.payload;
      })
      .addCase(getBankReconcilationReportRequest.rejected, (state, action) => {
        state.fetchBankReconcilationReportLoading = false;
        state.fetchBankReconcilationReportStatus = 'FAILED';
        state.fetchBankReconcilationReportError = action.payload;
      });
  },
});

export const { clearFetchBankReconcilationReportResponse } = getBankReconcilationReportSlice.actions;

export const getBankReconcilationReportSelector = (state: RootState) => state.GetBankReconcilationReport;

export default getBankReconcilationReportSlice.reducer;

import { Button, Modal } from 'flowbite-react';
import { CommonButton } from '../../../atoms/CommonButton';
import { Checkbox } from '../../../atoms/Checkbox';
import { SelectInput } from '../../../atoms/SelectInput';
import { useEffect, useState } from 'react';
import { Calendar } from '../../../atoms/Calendar';
import { DatePickerField } from '../../../atoms/DatePickerField';
import { formatDatePickerDate } from '../../../../utils/commonFunctions';
import moment from 'moment';
import { InputField } from '../../../atoms/InputField';
import { CPTSearchModal } from './CPTSearchModal';
import { HiOutlineSearch, HiShoppingCart } from 'react-icons/hi';
import { ModifierSearchModal } from './ModifierSearchModal';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks/hooks';
import {
  addVisitCPTCodeRequest,
  addVisitCPTCodeSelector,
  clearAddVisitCPTCodeResponse,
} from '../../../../redux/slices/patient/addVisitCPTCodeSlice';
import { CommonAlert } from '../../../atoms/Alert';
import {
  updateVisitCPTCodeRequest,
  clearUpdateVisitCPTCodeResponse,
  updateVisitCPTCodeSelector,
} from '../../../../redux/slices/patient/updateVisitCPTCodeSlice';
import { patientVisitEditCPTValidation } from '../../../../utils/patientValidation';
import { IoIosInformationCircleOutline } from 'react-icons/io';

const customSelectArr = [
  { id: 1, name: '1' },
  { id: 2, name: '2' },
  { id: 3, name: '3' },
  { id: 4, name: '4' },
  { id: 5, name: '5' },
  { id: 6, name: '6' },
  { id: 7, name: '7' },
];

const customUnitArr = [
  { id: 1, name: '1' },
  { id: 2, name: '2' },
  { id: 3, name: '3' },
  { id: 4, name: '4' },
  { id: 5, name: '5' },
  { id: 6, name: '6' },
  { id: 7, name: '7' },
  { id: 8, name: '8' },
  { id: 9, name: '9' },
  { id: 10, name: '10' },
  { id: 11, name: '11' },
  { id: 12, name: '12' },
];

const AddCPTModal = ({
  mode,
  editData = {},
  openAddCPT,
  setOpenAddCPT,
  visitId,
  icdCodesDataSource,
  cptCodesDataSource,
  modifiersDataSource,
  getCPTCodeList,
  icdDataSource,
  handleRemoveCPTCodeDataSource,
  selectedCPTId,
}: any) => {
  const dispatch = useAppDispatch();
  const { addVisitCPTCodeStatus, addVisitCPTCodeData, addVisitCPTCodeError } = useAppSelector(addVisitCPTCodeSelector);
  const { updateVisitCPTCodeStatus, updateVisitCPTCodeError } = useAppSelector(updateVisitCPTCodeSelector);

  const [groupCheckBox, setGroupCheckbox] = useState<any>([]);
  const [visitDate, setVisitDate] = useState<any>();
  const [otherFormData, setOtherFormData] = useState<any>(editData);
  const [visibleCPTSearch, setVisibleCPTSearch] = useState<boolean>(false);
  const [visibleModifierSearch, setVisibleModifierSearch] = useState<boolean>(false);
  const [selectedModifier, setSelectedModifier] = useState<string>('');
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertObj, setAlertObj] = useState({
    color: '',
    message: '',
    error: false,
  });
  const [errorObj, setErrorObj] = useState<any>({});

  // console.log('=== icdCodesDataSource ==', icdCodesDataSource, groupCheckBox);

  useEffect(() => {
    if (icdCodesDataSource?.length > 0) {
      setGroupCheckbox(icdCodesDataSource);
    }
  }, [icdCodesDataSource]);

  useEffect(() => {
    if (addVisitCPTCodeStatus === 'SUCCESS') {
      getCPTCodeList(visitId);
      setAlertObj({
        color: 'success',
        message: 'Successfully Added!',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
        setOpenAddCPT(false);
      }, 3000);
    } else if (addVisitCPTCodeStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: addVisitCPTCodeError?.message ?? 'Something went wrong !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
    dispatch(clearAddVisitCPTCodeResponse());
  }, [addVisitCPTCodeStatus]);

  useEffect(() => {
    if (updateVisitCPTCodeStatus === 'SUCCESS') {
      getCPTCodeList(visitId);
      setAlertObj({
        color: 'success',
        message: 'Successfully Updated!',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
        setOpenAddCPT(false);
      }, 3000);
    } else if (updateVisitCPTCodeStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: updateVisitCPTCodeError?.message ?? 'Something went wrong!',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
    }
    dispatch(clearUpdateVisitCPTCodeResponse());
  }, [updateVisitCPTCodeStatus]);

  const resetAlert = () => {
    setVisibleAlert(false);
    setAlertObj({ color: '', message: '', error: false });
  };

  const populateAlert = (color: string = '', error: boolean = false, message: any = '', errorObject: any = {}) => {
    setErrorObj(errorObject);
    setAlertObj({
      color: color,
      message: !error ? (
        <div>
          {Object.keys(errorObject)?.map((msg: any, index: any) => (
            <div key={index} className="flex items-center">
              <IoIosInformationCircleOutline />
              <span style={{ marginLeft: '8px' }}>{errorObject[msg]}</span>
            </div>
          ))}
        </div>
      ) : (
        message
      ),
      error: error,
    });
    setVisibleAlert(true);
  };

  const handleSubmit = () => {
    let icdCodeArr = [];
    for (let index = 0; index < groupCheckBox.length; index++) {
      const element = groupCheckBox[index];
      let obj: any = {};
      if (element?.checked) {
        obj['code'] = element?.code;
        obj['value'] = element?.value;
      }
      icdCodeArr[icdCodeArr.length] = obj;
    }

    let visitData: any = {
      icdCodes: icdCodeArr ?? [],
      visitDate: otherFormData?.visitDate
        ? moment(otherFormData?.visitDate).format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD'),
      cptId: otherFormData?.cptId,
      unit: parseInt(otherFormData?.unit),
      modifier1: otherFormData?.modifier1 ?? '',
      modifier2: otherFormData?.modifier2 ?? '',
      modifier3: otherFormData?.modifier3 ?? '',
      modifier4: otherFormData?.modifier4 ?? '',
    };

    const validationResults = patientVisitEditCPTValidation(visitData);
    // console.log('=== validationResults ===', validationResults);

    if (Object.keys(validationResults?.newErrors)?.length > 0) {
      populateAlert('failure', false, '', validationResults?.newErrors);
    } else {
      if (mode === 'EDIT') {
        if (selectedCPTId) {
          visitData['id'] = selectedCPTId;
        }

        dispatch(updateVisitCPTCodeRequest({ visitId, visitData }));
      } else {
        dispatch(addVisitCPTCodeRequest({ visitId, visitData }));
      }
    }
  };

  const handleCheckboxes = (e: any) => {
    const { name, checked } = e.target;
    // console.log('=== checked ===', groupCheckBox[0].code?.toString() === name, name);

    setGroupCheckbox((prev: any) =>
      prev.map((item: any) => (item?.code?.toString() === name ? { ...item, checked, value: undefined } : item))
    );
  };

  const handleRelatedSelect = (e: any) => {
    const { name, value } = e.target;
    // console.log('=== value ===', value, name);

    setGroupCheckbox((prev: any) => {
      // console.log('=== prev ===', prev, prev[0]?.code === name);
      return prev.map((item: any) => (item?.code?.toString() === name ? { ...item, value: parseInt(value) } : item));
    });
  };

  const onChangeVisitDate = (date: Date | null) => {
    setOtherFormData((prev: any) => ({ ...prev, visitDate: moment(date).format('YYYY-MM-DD') }));
  };

  const onChangeOtherFields = (e: any) => {
    setOtherFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onCloseCPTSearch = (data: boolean) => {
    setVisibleCPTSearch(data);
  };

  const onCloseModifierSearch = (data: boolean) => {
    setVisibleModifierSearch(data);
  };

  const handleAddedCPTRow = (data: any) => {
    setOtherFormData((prev: any) => ({ ...prev, cptId: data?.id, cptCode: data?.code }));
    // console.log('=== data - added row ===', data);
  };

  const handleRemoveCPTCode = () => {
    setOtherFormData((prevState: any) => {
      const { cptId: _, cptCode: __, ...newState } = prevState;
      return newState;
    });
  };

  const handleAddedModifierRow = (data: any) => {
    setOtherFormData((prev: any) => ({ ...prev, [selectedModifier]: data?.code }));
    // console.log('=== data - added row ===', data);
  };

  const handleDeleteModifiers = (id: string) => {
    setOtherFormData((prevState: any) => {
      const { [id]: _, ...newState } = prevState;
      return newState;
    });
  };

  const handleAlertClose = () => {
    resetAlert();
  };

  // console.log('=== groupCheckBox ===', groupCheckBox);

  return (
    <>
      <Modal show={openAddCPT} onClose={() => setOpenAddCPT(false)}>
        <Modal.Header>Add CPT Code</Modal.Header>
        <Modal.Body>
          {visibleAlert && !alertObj?.error && (
            <CommonAlert
              color={alertObj?.color}
              message={alertObj?.message}
              onClose={handleAlertClose}
              alertClassName="w-3/3"
            />
          )}
          <div className="grid md:grid-cols-3 gap-5 mt-6">
            {groupCheckBox?.length > 0 &&
              groupCheckBox?.map((item: any, key: any) => {
                return (
                  <div>
                    <Checkbox
                      label={item?.code}
                      onChange={handleCheckboxes}
                      name={item?.code}
                      defaultChecked={item?.checked}
                      required={true}
                    />
                    <SelectInput
                      options={customSelectArr}
                      name={item?.code}
                      onChange={handleRelatedSelect}
                      disabled={!item?.checked}
                      value={item?.value ?? ''}
                    />
                  </div>
                );
              })}
          </div>
          <div className="grid md:grid-cols-2 gap-5 mt-6">
            <InputField
              label="CPT"
              name="cptId"
              onChange={onChangeOtherFields}
              disabled={true}
              value={otherFormData?.cptCode ?? ''}
              required={true}
            />
            <div className="flex gap-2 mt-7">
              <CommonButton
                style={{ height: '37px' }}
                type="button"
                label={'Reset'}
                buttonType="secondary"
                onClick={() => handleRemoveCPTCode()}
              />
              <CommonButton
                style={{ height: '37px' }}
                type="button"
                label={'Search'}
                buttonType="primary"
                onClick={() => setVisibleCPTSearch(true)}
              />
            </div>
            {/* <CustomSearchButton onClick={() => setVisibleCPTSearch(true)} /> */}
          </div>
          <div className="grid md:grid-cols-2 gap-5 mt-6">
            {/* <Calendar
              onSelectedDateChanged={onChangeVisitDate}
              name="visitDate"
              label="Visit Date"
              defaultDate={editData?.visitDate ? new Date(editData?.visitDate) : undefined}
            /> */}
            <DatePickerField
              label="Visit Date"
              name="visitDate"
              selectedDate={formatDatePickerDate(editData?.visitDate ?? otherFormData?.visitDate)}
              onChange={onChangeVisitDate}
            />
            <SelectInput
              label="Unit"
              options={customUnitArr}
              defaultValue={editData?.unit ?? undefined}
              name="unit"
              onChange={onChangeOtherFields}
              required={true}
            />
          </div>
          <div className="grid md:grid-cols-4 gap-5 mt-6">
            <div className="flex" style={{ alignItems: 'end' }}>
              <InputField
                label="Modifier 1"
                name="modifier1"
                onChange={onChangeOtherFields}
                disabled={true}
                value={otherFormData?.modifier1 ? otherFormData?.modifier1 : ''}
              />
              {otherFormData?.modifier1 ? (
                <CustomBinButton onClick={() => handleDeleteModifiers('modifier1')} />
              ) : (
                <CustomSearchButton
                  onClick={() => {
                    setVisibleModifierSearch(true);
                    setSelectedModifier('modifier1');
                  }}
                />
              )}
            </div>

            <div className="flex" style={{ alignItems: 'end' }}>
              <InputField
                label="Modifier 2"
                name="modifier2"
                onChange={onChangeOtherFields}
                disabled={true}
                value={otherFormData?.modifier2 ? otherFormData?.modifier2 : ''}
              />
              {otherFormData?.modifier2 ? (
                <CustomBinButton onClick={() => handleDeleteModifiers('modifier2')} />
              ) : (
                <CustomSearchButton
                  onClick={() => {
                    setVisibleModifierSearch(true);
                    setSelectedModifier('modifier2');
                  }}
                />
              )}
            </div>

            <div className="flex" style={{ alignItems: 'end' }}>
              <InputField
                label="Modifier 3"
                name="modifier3"
                onChange={onChangeOtherFields}
                disabled={true}
                value={otherFormData?.modifier3 ? otherFormData?.modifier3 : ''}
              />
              {otherFormData?.modifier3 ? (
                <CustomBinButton onClick={() => handleDeleteModifiers('modifier3')} />
              ) : (
                <CustomSearchButton
                  onClick={() => {
                    setVisibleModifierSearch(true);
                    setSelectedModifier('modifier3');
                  }}
                />
              )}
            </div>

            <div className="flex" style={{ alignItems: 'end' }}>
              <InputField
                label="Modifier 4"
                name="modifier4"
                onChange={onChangeOtherFields}
                disabled={true}
                value={otherFormData?.modifier4 ? otherFormData?.modifier4 : ''}
              />
              {otherFormData?.modifier4 ? (
                <CustomBinButton onClick={() => handleDeleteModifiers('modifier4')} />
              ) : (
                <CustomSearchButton
                  onClick={() => {
                    setVisibleModifierSearch(true);
                    setSelectedModifier('modifier4');
                  }}
                />
              )}
              {/* <CustomSearchButton onClick={() => setVisibleCPTSearch(true)} /> */}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <CommonButton type="button" label={'Close'} buttonType="secondary" onClick={() => setOpenAddCPT(false)} />
          <CommonButton
            type="button"
            label={'Submit'}
            buttonType={!otherFormData?.cptId ? 'disabled' : 'primary'}
            onClick={handleSubmit}
            // disabled={!otherFormData?.cptCode}
          />
        </Modal.Footer>
      </Modal>
      {visibleCPTSearch && (
        <CPTSearchModal
          cptModalOpen={visibleCPTSearch}
          setOpenCPTModal={onCloseCPTSearch}
          cptCodesDataSource={cptCodesDataSource}
          handleAddedCPTRow={handleAddedCPTRow}
          modifiersDataSource={modifiersDataSource}
          handleRemoveCPTCodeDataSource={handleRemoveCPTCodeDataSource}
        />
      )}
      {visibleModifierSearch && (
        <ModifierSearchModal
          modifiersDataSource={modifiersDataSource}
          modifierModalOpen={visibleModifierSearch}
          setOpenModifierModal={onCloseModifierSearch}
          handleAddedModifierRow={handleAddedModifierRow}
        />
      )}
    </>
  );
};

const CustomSearchButton = ({ onClick }: any) => {
  return (
    <button
      onClick={onClick}
      style={{
        borderRadius: '5px',
        background: '#11C8DE',
        height: '42px',
        marginLeft: '5px',
        width: '35px !important',
      }}
    >
      <svg
        width="35px"
        height="35px"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        //   className="w-4 h-4"
      >
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          {' '}
          <path
            d="M11 6C13.7614 6 16 8.23858 16 11M16.6588 16.6549L21 21M19 11C19 15.4183 15.4183 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11Z"
            stroke="#6b7280"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>{' '}
        </g>
      </svg>
    </button>
  );
};

const CustomBinButton = ({ onClick }: any) => {
  return (
    <button
      onClick={onClick}
      style={{
        borderRadius: '5px',
        background: '#FAB1A0',
        height: '42px',
        marginLeft: '5px',
        width: '35px !important',
      }}
    >
      <svg width="35px" height="35px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          {' '}
          <path
            d="M1.5 3.75C1.08579 3.75 0.75 4.08579 0.75 4.5C0.75 4.91421 1.08579 5.25 1.5 5.25V3.75ZM22.5 5.25C22.9142 5.25 23.25 4.91421 23.25 4.5C23.25 4.08579 22.9142 3.75 22.5 3.75V5.25ZM1.5 5.25H22.5V3.75H1.5V5.25Z"
            fill="#71717A"
          ></path>{' '}
          <path
            d="M9.75 1.5V0.75V1.5ZM8.25 3H7.5H8.25ZM7.5 4.5C7.5 4.91421 7.83579 5.25 8.25 5.25C8.66421 5.25 9 4.91421 9 4.5H7.5ZM15 4.5C15 4.91421 15.3358 5.25 15.75 5.25C16.1642 5.25 16.5 4.91421 16.5 4.5H15ZM15.75 3H16.5H15.75ZM14.25 0.75H9.75V2.25H14.25V0.75ZM9.75 0.75C9.15326 0.75 8.58097 0.987053 8.15901 1.40901L9.21967 2.46967C9.36032 2.32902 9.55109 2.25 9.75 2.25V0.75ZM8.15901 1.40901C7.73705 1.83097 7.5 2.40326 7.5 3H9C9 2.80109 9.07902 2.61032 9.21967 2.46967L8.15901 1.40901ZM7.5 3V4.5H9V3H7.5ZM16.5 4.5V3H15V4.5H16.5ZM16.5 3C16.5 2.40326 16.2629 1.83097 15.841 1.40901L14.7803 2.46967C14.921 2.61032 15 2.80109 15 3H16.5ZM15.841 1.40901C15.419 0.987053 14.8467 0.75 14.25 0.75V2.25C14.4489 2.25 14.6397 2.32902 14.7803 2.46967L15.841 1.40901Z"
            fill="#71717A"
          ></path>{' '}
          <path
            d="M9 17.25C9 17.6642 9.33579 18 9.75 18C10.1642 18 10.5 17.6642 10.5 17.25H9ZM10.5 9.75C10.5 9.33579 10.1642 9 9.75 9C9.33579 9 9 9.33579 9 9.75H10.5ZM10.5 17.25V9.75H9V17.25H10.5Z"
            fill="#71717A"
          ></path>{' '}
          <path
            d="M13.5 17.25C13.5 17.6642 13.8358 18 14.25 18C14.6642 18 15 17.6642 15 17.25H13.5ZM15 9.75C15 9.33579 14.6642 9 14.25 9C13.8358 9 13.5 9.33579 13.5 9.75H15ZM15 17.25V9.75H13.5V17.25H15Z"
            fill="#71717A"
          ></path>{' '}
          <path
            d="M18.865 21.124L18.1176 21.0617L18.1176 21.062L18.865 21.124ZM17.37 22.5L17.3701 21.75H17.37V22.5ZM6.631 22.5V21.75H6.63093L6.631 22.5ZM5.136 21.124L5.88343 21.062L5.88341 21.0617L5.136 21.124ZM4.49741 4.43769C4.46299 4.0249 4.10047 3.71818 3.68769 3.75259C3.2749 3.78701 2.96818 4.14953 3.00259 4.56231L4.49741 4.43769ZM20.9974 4.56227C21.0318 4.14949 20.7251 3.78698 20.3123 3.75259C19.8995 3.7182 19.537 4.02495 19.5026 4.43773L20.9974 4.56227ZM18.1176 21.062C18.102 21.2495 18.0165 21.4244 17.878 21.5518L18.8939 22.6555C19.3093 22.2732 19.5658 21.7486 19.6124 21.186L18.1176 21.062ZM17.878 21.5518C17.7396 21.6793 17.5583 21.75 17.3701 21.75L17.3699 23.25C17.9345 23.25 18.4785 23.0379 18.8939 22.6555L17.878 21.5518ZM17.37 21.75H6.631V23.25H17.37V21.75ZM6.63093 21.75C6.44274 21.75 6.26142 21.6793 6.12295 21.5518L5.10713 22.6555C5.52253 23.0379 6.06649 23.25 6.63107 23.25L6.63093 21.75ZM6.12295 21.5518C5.98449 21.4244 5.89899 21.2495 5.88343 21.062L4.38857 21.186C4.43524 21.7486 4.69172 22.2732 5.10713 22.6555L6.12295 21.5518ZM5.88341 21.0617L4.49741 4.43769L3.00259 4.56231L4.38859 21.1863L5.88341 21.0617ZM19.5026 4.43773L18.1176 21.0617L19.6124 21.1863L20.9974 4.56227L19.5026 4.43773Z"
            fill="#71717A"
          ></path>{' '}
        </g>
      </svg>
    </button>
  );
};

export { CustomBinButton, CustomSearchButton };
export default AddCPTModal;

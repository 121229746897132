import { Checkbox } from '../../atoms/Checkbox';
import { InputField } from '../../atoms/InputField';
import { SelectInput } from '../../atoms/SelectInput';
import { TextArea } from '../../atoms/TextArea';

export const PaymentRightSheetForm = ({ onChange, createFormData, mode, billingCodeTypes }: any) => {
  const formatBillingCodesTypes = (codes: any[]) => {
    return codes.map((code) => ({
      id: code.id,
      name: code.name,
    }));
  };

  return (
    <>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <InputField label="Code" name="code" onChange={onChange} value={createFormData?.code ?? ''} required={true} />

        <SelectInput
          label="Type"
          name="type"
          options={formatBillingCodesTypes(billingCodeTypes)}
          enableDefaultPlaceholder={true}
          onChange={onChange}
          value={createFormData?.type ?? undefined}
          required={true}
        />

        <Checkbox
          label="Active"
          name="isActive"
          onChange={onChange}
          checked={Boolean(createFormData?.isActive || false)}
        />
      </div>

      <div className="grid md:grid-cols-1 gap-4 mt-6">
        <TextArea
          label="Description"
          name="description"
          onChange={onChange}
          value={createFormData?.description ?? ''}
        />
      </div>

      <div className="grid md:grid-cols-2 gap-4 mt-6">
        <Checkbox
          label="Display in Statement"
          name="displayInStatement"
          onChange={onChange}
          checked={Boolean(createFormData?.displayInStatement || false)}
        />
        {/* {mode === 'EDIT' && (
            <Checkbox
              label="Disable in Report"
              onChange={onChange}
              name="displayInReport"
              value={codeData?.displayInReport}
              defaultChecked={codeData?.displayInReport}
            />
          )} */}
      </div>
    </>
  );
};

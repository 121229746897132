import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { FetchCrossoverAgingSummaryInitialInterface } from '../../../interfaces/reportsInterfaces';
import { getCrossoverAgingSummaryAPIRequest } from '../../../api/reports/accountRecievable';

const initialState: FetchCrossoverAgingSummaryInitialInterface = {
  fetchCrossoverAgingSummaryLoading: false,
  fetchCrossoverAgingSummaryData: {},
  fetchCrossoverAgingSummaryStatus: 'IDLE',
  fetchCrossoverAgingSummaryError: null,
};

export const getCrossoverAgingSummaryRequest: any = createAsyncThunk(
  'reports/crossover-aging-summary/get',
  async (thunkAPI: any) => {
    try {
      const response: any = await getCrossoverAgingSummaryAPIRequest();
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getCrossoverAgingSummarySlice = createSlice({
  name: 'fetchCrossoverAgingSummary',
  initialState,
  reducers: {
    clearFetchCrossoverAgingSummaryResponse: (state) => {
      state.fetchCrossoverAgingSummaryLoading = false;
      state.fetchCrossoverAgingSummaryStatus = 'IDLE';
      state.fetchCrossoverAgingSummaryData = {};
      state.fetchCrossoverAgingSummaryError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCrossoverAgingSummaryRequest.pending, (state, action) => {
        state.fetchCrossoverAgingSummaryLoading = true;
        state.fetchCrossoverAgingSummaryStatus = 'PENDING';
      })
      .addCase(getCrossoverAgingSummaryRequest.fulfilled, (state, action) => {
        state.fetchCrossoverAgingSummaryLoading = false;
        state.fetchCrossoverAgingSummaryData = action.payload;
        state.fetchCrossoverAgingSummaryStatus = 'SUCCESS';
      })
      .addCase(getCrossoverAgingSummaryRequest.rejected, (state, action) => {
        state.fetchCrossoverAgingSummaryLoading = false;
        state.fetchCrossoverAgingSummaryError = action.payload;
        state.fetchCrossoverAgingSummaryStatus = 'FAILED';
      });
  },
});

export const { clearFetchCrossoverAgingSummaryResponse } = getCrossoverAgingSummarySlice.actions;

export const getCrossoverAgingSummarySelector = (state: RootState) => state.GetCrossoverAgingSumamry;

export default getCrossoverAgingSummarySlice.reducer;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  FetchBankReconcilationRInterface,
  FetchBankReconcilationRInitialInterface,
} from '../../../interfaces/reportsInterfaces';

const initialState: FetchBankReconcilationRInitialInterface = {
  fetchBankReconcilationRLoading: false,
  fetchBankReconcilationRData: {
    currentPage: 0,
    items: [],
    totalItems: 0,
    totalPages: 0,
  },
  fetchBankReconcilationRError: null,
  fetchBankReconcilationRStatus: 'IDLE',
};

export const getBankReconcilationRRequest: any = createAsyncThunk(
  'reports/bank-reconcilation-r/get',
  async (reportData: FetchBankReconcilationRInterface, thunkAPI: any) => {
    try {
      //   const response: any = await getBankReconcilationRAPIRequest(reportData);
      //   return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getBankReconcilationRSlice = createSlice({
  name: 'fetchBankReconcilationR',
  initialState,
  reducers: {
    clearFetchBankReconcilationRResponse: (state) => {
      state.fetchBankReconcilationRLoading = false;
      state.fetchBankReconcilationRData = { currentPage: 0, items: [], totalItems: 0, totalPages: 0 };
      state.fetchBankReconcilationRStatus = 'IDLE';
      state.fetchBankReconcilationRError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBankReconcilationRRequest.pending, (state, action) => {
        state.fetchBankReconcilationRLoading = true;
        state.fetchBankReconcilationRStatus = 'PENDING';
      })
      .addCase(getBankReconcilationRRequest.fulfilled, (state, action) => {
        state.fetchBankReconcilationRLoading = false;
        state.fetchBankReconcilationRStatus = 'SUCCESS';
        state.fetchBankReconcilationRData = action.payload;
      })
      .addCase(getBankReconcilationRRequest.rejected, (state, action) => {
        state.fetchBankReconcilationRLoading = false;
        state.fetchBankReconcilationRStatus = 'FAILED';
        state.fetchBankReconcilationRError = action.payload;
      });
  },
});

export const { clearFetchBankReconcilationRResponse } = getBankReconcilationRSlice.actions;

export const getBankReconcilationRSelector = (state: RootState) => state.GetBankReconcilationR;

export default getBankReconcilationRSlice.reducer;

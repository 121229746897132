import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  FetchCrossoverCarriersInterface,
  FetchCrossoverCarriersInitialInterface,
} from '../../../interfaces/reportsInterfaces';

const initialState: FetchCrossoverCarriersInitialInterface = {
  fetchCrossoverCarriersLoading: false,
  fetchCrossoverCarriersData: {
    currentPage: 0,
    items: [],
    totalItems: 0,
    totalPages: 0,
  },
  fetchCrossoverCarriersError: null,
  fetchCrossoverCarriersStatus: 'IDLE',
};

export const getCrossoverCarriersRequest: any = createAsyncThunk(
  'reports/crossoverCarriers/get',
  async (reportData: FetchCrossoverCarriersInterface, thunkAPI: any) => {
    try {
      //   const response: any = await getCrossoverCarriersAPIRequest(reportData);
      //   return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getCrossoverCarriersSlice = createSlice({
  name: 'fetchCrossoverCarriers',
  initialState,
  reducers: {
    clearFetchCrossoverCarriersResponse: (state) => {
      state.fetchCrossoverCarriersLoading = false;
      state.fetchCrossoverCarriersData = { currentPage: 0, items: [], totalItems: 0, totalPages: 0 };
      state.fetchCrossoverCarriersStatus = 'IDLE';
      state.fetchCrossoverCarriersError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCrossoverCarriersRequest.pending, (state, action) => {
        state.fetchCrossoverCarriersLoading = true;
        state.fetchCrossoverCarriersStatus = 'PENDING';
      })
      .addCase(getCrossoverCarriersRequest.fulfilled, (state, action) => {
        state.fetchCrossoverCarriersLoading = false;
        state.fetchCrossoverCarriersStatus = 'SUCCESS';
        state.fetchCrossoverCarriersData = action.payload;
      })
      .addCase(getCrossoverCarriersRequest.rejected, (state, action) => {
        state.fetchCrossoverCarriersLoading = false;
        state.fetchCrossoverCarriersStatus = 'FAILED';
        state.fetchCrossoverCarriersError = action.payload;
      });
  },
});

export const { clearFetchCrossoverCarriersResponse } = getCrossoverCarriersSlice.actions;

export const getCrossoverCarriersSelector = (state: RootState) => state.GetCrossoverCarriers;

export default getCrossoverCarriersSlice.reducer;

import { useState, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks/hooks';
import moment from 'moment';

import { MainHeader } from '../../components/mainHeader/MainHeader';
import { PageTopic } from '../../components/pageTopic/PageTopic';
import { EmptyContent } from '../../components/emptyContent/EmptyContent';
import { CommonAlert } from '../../components/atoms/Alert';
import { Spinner } from '../../components/atoms/Spinner';
import { FinancialDashboardByPayerMixCustomTable } from '../../components/table/FinancialDashboardByPayerMixCustomTable';
import { FinancialDashboardReportAdvanceSearch } from '../../components/tableSearch/FinancialDashboardReportAdvanceSearch';

import {
  getDoctorFinancialReportByPayerMixRequest,
  getDoctorFinancialReportByPayerMixSelector,
  clearDoctorFinancialByPayerMixReportResponse,
} from '../../redux/slices/reports/getDoctorFinancialByPayerMixReportSlice';
import { getSystemFacilityRequest } from '../../redux/slices/system/getSystemFacilitiesSlice';
import { getSystemProvidersRequest } from '../../redux/slices/system/getSystemProvidersSlice';

import { formatCurrency } from '../../utils/commonFunctions';

const columns = [
  {
    title: 'Month',
    dataIndex: 'month',
    key: 'month',
  },
  {
    title: 'Payor Mix',
    dataIndex: 'payorMix',
    key: 'payorMix',
  },
  {
    title: '# of Visits',
    dataIndex: 'noOfVisits',
    key: 'noOfVisits',
  },
  {
    title: 'Charges',
    dataIndex: 'charges',
    key: 'charges',
  },
  {
    title: 'Charge Adjust',
    dataIndex: 'chargeAdjust',
    key: 'chargeAdjust',
    render: (text: any) => (text != null ? formatCurrency(text) : ''),
  },
  {
    title: 'Insurance Write Off',
    dataIndex: 'insuranceWriteOff',
    key: 'insuranceWriteOff',
    render: (text: any) => (text != null ? formatCurrency(text) : ''),
  },
  {
    title: 'Net Charges',
    dataIndex: 'netCharges',
    key: 'netCharges',
    render: (text: any) => (text != null ? formatCurrency(text) : ''),
  },
  {
    title: 'Personal Receipts',
    dataIndex: 'personalReceipts',
    key: 'personalReceipts',
    render: (text: any) => (text != null ? formatCurrency(text) : ''),
  },
  {
    title: 'Insurance Receipts',
    dataIndex: 'insuranceReceipts',
    key: 'insuranceReceipts',
    render: (text: any) => (text != null ? formatCurrency(text) : ''),
  },
  {
    title: 'Receipt Adjust',
    dataIndex: 'receiptAdjust',
    key: 'receiptAdjust',
    render: (text: any) => (text != null ? formatCurrency(text) : ''),
  },
  {
    title: 'Net Receipts',
    dataIndex: 'netReceipts',
    key: 'netReceipts',
    render: (text: any) => (text != null ? formatCurrency(text) : ''),
  },
  {
    title: 'Change In A/R',
    dataIndex: 'changeInAR',
    key: 'changeInAR',
    render: (text: any) => (text != null ? formatCurrency(text) : ''),
  },
  {
    title: 'Percentage',
    dataIndex: 'percentage',
    key: 'percentage',
    render: (text: any) => (text != null ? formatCurrency(text) : ''),
  },
  {
    title: '0 - 30 Days',
    dataIndex: 'days0To30',
    key: 'days0To30',
    render: (text: any) => (text != null ? formatCurrency(text) : ''),
  },
  {
    title: '30 - 60 Days',
    dataIndex: 'days30To60',
    key: 'days30To60',
    render: (text: any) => (text != null ? formatCurrency(text) : ''),
  },
  {
    title: '60 - 90 Days',
    dataIndex: 'days60To90',
    key: 'days60To90',
    render: (text: any) => (text != null ? formatCurrency(text) : ''),
  },
  {
    title: '91 - 120 Days',
    dataIndex: 'days90To120',
    key: 'days90To120',
    render: (text: any) => (text != null ? formatCurrency(text) : ''),
  },
  {
    title: '>120 Days',
    dataIndex: 'daysAbove120',
    key: 'daysAbove120',
    render: (text: any) => (text != null ? formatCurrency(text) : ''),
  },
];

const dummyDataSource = {
  startingBalanceAR: {
    changeInAR: 309396.03,
    days0To30: 670.0,
    days30To60: 94343.43,
    days60To90: 43434.0,
    days90To120: 0.0,
    daysAbove120: 901.0,
  },
  monthlyData: [
    {
      month: 'January',
      totals: {
        noOfVisits: 932,
        charges: 3240.0,
        chargeAdjust: 4540.0,
        insuranceWriteOff: 0.0,
        netCharges: 0.0,
        personalReceipts: 0.0,
        insuranceReceipts: 0.0,
        receiptAdjust: 0.0,
        netReceipts: 0.0,
        changeInAR: 0.0,
        days0To30: 0.0,
        days30To60: 0.0,
        days60To90: 0.0,
        days90To120: 0.0,
        daysAbove120: 0.0,
      },
      payorMix: [
        {
          name: 'Medicaid',
          noOfVisits: 0,
          charges: 0.0,
          chargeAdjust: 0.0,
          insuranceWriteOff: 0.0,
          netCharges: 0.0,
          personalReceipts: 0.0,
          insuranceReceipts: 0.0,
          receiptAdjust: 0.0,
          netReceipts: 0.0,
          changeInAR: 0.0,
          days0To30: 0.0,
          days30To60: 0.0,
          days60To90: 0.0,
          days90To120: 0.0,
          daysAbove120: 0.0,
        },
        {
          name: 'Medicaid MCO',
          noOfVisits: 0,
          charges: 0.0,
          chargeAdjust: 0.0,
          insuranceWriteOff: 0.0,
          netCharges: 0.0,
          personalReceipts: 0.0,
          insuranceReceipts: 0.0,
          receiptAdjust: 0.0,
          netReceipts: 0.0,
          changeInAR: 0.0,
          days0To30: 0.0,
          days30To60: 0.0,
          days60To90: 0.0,
          days90To120: 0.0,
          daysAbove120: 0.0,
        },
        {
          name: 'Private Insurance',
          noOfVisits: 0,
          charges: 0.0,
          chargeAdjust: 0.0,
          insuranceWriteOff: 0.0,
          netCharges: 0.0,
          personalReceipts: 0.0,
          insuranceReceipts: 0.0,
          receiptAdjust: 0.0,
          netReceipts: 0.0,
          changeInAR: 0.0,
          days0To30: 0.0,
          days30To60: 0.0,
          days60To90: 0.0,
          days90To120: 0.0,
          daysAbove120: 0.0,
        },
      ],
    },
    {
      month: 'February',
      totals: {
        noOfVisits: 932,
        charges: 3240.0,
        chargeAdjust: 4540.0,
        insuranceWriteOff: 0.0,
        netCharges: 0.0,
        personalReceipts: 0.0,
        insuranceReceipts: 0.0,
        receiptAdjust: 0.0,
        netReceipts: 0.0,
        changeInAR: 0.0,
        days0To30: 0.0,
        days30To60: 0.0,
        days60To90: 0.0,
        days90To120: 0.0,
        daysAbove120: 0.0,
      },
      payorMix: [
        {
          name: 'Medicaid',
          noOfVisits: 0,
          charges: 0.0,
          chargeAdjust: 0.0,
          insuranceWriteOff: 0.0,
          netCharges: 0.0,
          personalReceipts: 0.0,
          insuranceReceipts: 0.0,
          receiptAdjust: 0.0,
          netReceipts: 0.0,
          changeInAR: 0.0,
          days0To30: 0.0,
          days30To60: 0.0,
          days60To90: 0.0,
          days90To120: 0.0,
          daysAbove120: 0.0,
        },
      ],
    },
  ],
  totalData: {
    totals: {
      noOfVisits: 16944,
      charges: 2764540.0,
      chargeAdjust: 32383.54,
      insuranceWriteOff: 915223.75,
      netCharges: 1816932.71,
      personalReceipts: 0.0,
      insuranceReceipts: 1514433.38,
      receiptAdjust: 0.0,
      netReceipts: 1514433.38,
      changeInAR: 302499.33,
      days0To30: 0.0,
      days30To60: 0.0,
      days60To90: 0.0,
      days90To120: 0.0,
      daysAbove120: 0.0,
    },
    payorMix: [
      {
        name: 'Medicaid',
        noOfVisits: 16,
        charges: 2176.0,
        chargeAdjust: 0.0,
        insuranceWriteOff: 0.0,
        netCharges: 2176.0,
        personalReceipts: 0.0,
        insuranceReceipts: 0.0,
        receiptAdjust: 0.0,
        netReceipts: 2176.0,
        changeInAR: 0.0,
        days0To30: 0.0,
        days30To60: 0.0,
        days60To90: 0.0,
        days90To120: 0.0,
        daysAbove120: 0.0,
      },
      {
        name: 'Medicare MCO',
        noOfVisits: 0,
        charges: 0.0,
        chargeAdjust: 0.0,
        insuranceWriteOff: 0.0,
        netCharges: 0.0,
        personalReceipts: 0.0,
        insuranceReceipts: 0.0,
        receiptAdjust: 0.0,
        netReceipts: 0.0,
        changeInAR: 0.0,
        days0To30: 0.0,
        days30To60: 0.0,
        days60To90: 0.0,
        days90To120: 0.0,
        daysAbove120: 0.0,
      },
    ],
  },
};

const dataSource = [
  {
    doctor: 'John',
    noOfVisits: '1',
    charges: '$109.00',
    allowedAmount: '$0.00	',
    chargeAdjust: '$0.00',
    insuranceWriteOff: '$0.00	',
    netCharges: '$109.00',
    personalReceipts: '$0.00',
    insuranceReceipts: '$0.00',
    receiptAdjust: '$0.00',
    netReceipts: '$0.00',
    changeInAR: '$109.00',
  },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  {
    id: 'financial-dashboard-payer-mix',
    label: 'Financial Dashboard Report by Payer Mix',
    status: 'active',
    link: 'financial-dashboard-payer-mix',
  },
];

export const FinancialDashboardPayerMixReportPage = () => {
  const dispatch = useAppDispatch();

  const {
    fetchDoctorFinancialReportByPayerMixStatus,
    fetchDoctorFinancialReportByPayerMixData,
    fetchDoctorFinancialReportByPayerMixLoading,
  } = useAppSelector(getDoctorFinancialReportByPayerMixSelector);

  const currentYear = moment().year();

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [filterObject, setFilterObject] = useState<any>({ year: currentYear.toString(), filterBy: 'DATE_OF_SERVICE' });

  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });

  useEffect(() => {
    fetchSystemProviders();
    fetchSystemFacilities();
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  }, []);

  useEffect(() => {
    if (filterObject) {
      fetchDoctorFinancialByPayerMixReport();
    }
  }, [filterObject]);

  useEffect(() => {
    if (fetchDoctorFinancialReportByPayerMixStatus === 'SUCCESS') {
      dispatch(clearDoctorFinancialByPayerMixReportResponse());
    } else if (fetchDoctorFinancialReportByPayerMixStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearDoctorFinancialByPayerMixReportResponse());
    }
  }, [fetchDoctorFinancialReportByPayerMixStatus]);

  const fetchDoctorFinancialByPayerMixReport = async () => {
    dispatch(getDoctorFinancialReportByPayerMixRequest({ filters: filterObject }));
  };

  const fetchSystemProviders = () => {
    dispatch(getSystemProvidersRequest());
  };

  const fetchSystemFacilities = () => {
    dispatch(getSystemFacilityRequest());
  };

  const handleSearch = (data: any) => {
    const updatedData = { ...data };

    if (!updatedData?.providerId || updatedData?.providerId === '') {
      delete updatedData.providerId;
    } else {
      updatedData.providerId = parseInt(updatedData?.providerId, 10);
    }

    if (!updatedData?.providerType || updatedData?.providerType === '') {
      delete updatedData.providerType;
    } else {
      updatedData.providerType = parseInt(updatedData?.providerType, 10);
    }

    if (!updatedData?.facilityId || updatedData?.facilityId === '') {
      delete updatedData.facilityId;
    } else {
      updatedData.facilityId = parseInt(updatedData?.facilityId, 10);
    }

    if (!updatedData?.state || updatedData?.state === '') {
      delete updatedData.state;
    }

    setFilterObject(updatedData);
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic
        mainTitle="Financial Dashboard Report by Payer Mix"
        enablePrimaryButton={false}
        breadCrumbArr={breadCrumbArr}
      />

      <FinancialDashboardReportAdvanceSearch onSubmit={handleSearch} />

      {visibleAlert && alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}

      {fetchDoctorFinancialReportByPayerMixLoading ? (
        <Spinner />
      ) : (
        <>
          {dummyDataSource ? (
            <>
              <div className="overflow-x-auto">
                <FinancialDashboardByPayerMixCustomTable columns={columns} dataSource={dummyDataSource} />
              </div>
            </>
          ) : (
            <EmptyContent mode="VIEW" enableCreateButton={false} />
          )}
        </>
      )}
    </div>
  );
};

import React from 'react';
import DatePicker from 'react-datepicker';
import { Tooltip } from 'flowbite-react';
import { BsInfoCircle, BsCalendar } from 'react-icons/bs';
import { DatePickerPropsInterface } from '../../interfaces/atomInterfaces';

import 'react-datepicker/dist/react-datepicker.css';

export const DatePickerField: React.FC<DatePickerPropsInterface> = ({
  enableLabel = true,
  label = '',
  name,
  required = false,
  onChange,
  selectedDate,
  placeholder = 'MM/DD/YYYY',
  disabled = false,
  tooltip = '',
  className = 'bg-gray1 border border-gray2 text-gray9 text-sm rounded-lg focus:ring-1 focus:ring-primaryDefault focus:border-primaryDefault outline-0 block w-full p-2.5 dark:bg-gray9 dark:border-gray8 dark:placeholder-gray4 dark:text-white dark:focus:ring-primaryDefault dark:focus:border-primaryDefault',
  error = false,
  errorMessage = '',
}) => {
  return (
    <>
      <div>
        {enableLabel && (
          <label className="block mb-2 text-sm font-normal text-gray-900 dark:text-white flex items-center">
            {label} {required && <span className="text-red-500">*</span>}
            {tooltip && (
              <span className="ml-1">
                <Tooltip content={tooltip} placement="top">
                  <BsInfoCircle className="text-gray-500 cursor-pointer" />
                </Tooltip>
              </span>
            )}
          </label>
        )}

        <DatePicker
          selected={selectedDate}
          onChange={onChange}
          placeholderText={placeholder}
          disabled={disabled}
          className={`${className} ${disabled ? 'opacity-80 cursor-not-allowed' : ''}`}
          name={name}
          scrollableYearDropdown
          showYearDropdown
          showMonthDropdown
          popperProps={{ strategy: 'fixed' }}
        />

        {/* {error && errorMessage && (
          <label className="block mt-2 mb-2 text-sm font-normal text-red-900 dark:text-white">{errorMessage}</label>
        )} */}
      </div>
    </>
  );
};

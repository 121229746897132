import { authenticatedRequest } from '../../utils/commonAxios';
import {
  FetchPrimaryAgingReportInterface,
  FetchSecondaryAgingReportInterface,
  FetchCrossoverAgingReportInterface,
  FetchArAnalysisInterface,
  FetchFinancialDRArInterface,
} from '../../interfaces/reportsInterfaces';

export const getPrimaryAgingReportAPIRequest = async (reportData: FetchPrimaryAgingReportInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/report/account-receivable/primary-insurance`,
    'get',
    {}
  );
};

export const getSecondaryAgingReportAPIRequest = async (reportData: FetchSecondaryAgingReportInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/report/account-receivable/secondary-insurance`,
    'get',
    {}
  );
};

export const getCrossoverAgingReportAPIRequest = async (reportData: FetchCrossoverAgingReportInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/report/account-receivable/cross-over`,
    'get',
    {}
  );
};

export const getArAnalysisAPIRequest = async (reportData: FetchArAnalysisInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/report/account-receivable/ar-analysis`,
    'post',
    { data: reportData?.filters ?? {} }
  );
};

export const getFinancialDRArAPIRequest = async (reportData: FetchFinancialDRArInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/report/account-receivable/financial-dashboard-ar`,
    'post',
    { data: reportData?.filters ?? {} }
  );
};

export const getPrimaryAgingSummaryAPIRequest = async () => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/report/account-receivable/primary-insurance-summery`,
    'get',
    {}
  );
};

export const getSecondaryAgingSummaryAPIRequest = async () => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/report/account-receivable/secondary-insurance-summery`,
    'get',
    {}
  );
};

export const getCrossoverAgingSummaryAPIRequest = async () => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/report/account-receivable/cross-over-summery`,
    'get',
    {}
  );
};

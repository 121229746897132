import { useState, useEffect } from 'react';
import { StepperHeaderInterface } from '../../interfaces/commonInterfaces';
import './stepper.css';
import { CommonButton } from '../atoms/CommonButton';

const stepperData = [
  { id: 1, label: 'Demographics' },
  { id: 2, label: 'Address' },
  { id: 3, label: 'Insurances' },
  { id: 4, label: 'Case' },
  { id: 5, label: 'Visit' },
  { id: 6, label: 'Claim' },
  { id: 7, label: 'Transaction' },
];

export const Stepper: React.FC<StepperHeaderInterface> = ({
  dataSource = stepperData,
  clickStepper,
  enableNotes = false,
  onClickNotes,
  selectedTab,
}) => {
  const [selectedStepper, setSelectedStepper] = useState(1);

  useEffect(() => {
    if (typeof selectedTab === 'number') {
      setSelectedStepper(selectedTab);
    }
  }, [selectedTab]);

  return (
    <>
      <div className="stepper">
        <ol className="stepper__list">
          {dataSource?.map((item: any, key: any) => {
            return (
              <li className="stepper__list-item">
                <span className="stepper__list-item-content">
                  <span
                    className={`stepper__list-item-content-num ${selectedStepper == item?.id ? 'active' : ''}`}
                    onClick={() => {
                      clickStepper && clickStepper(item?.id);
                      setSelectedStepper(key + 1);
                    }}
                  >
                    {item?.id}
                  </span>
                  <span>{item?.label}</span>
                </span>
              </li>
            );
          })}
          {enableNotes && (
            <div style={{ height: '35px', marginBottom: '20px' }}>
              <CommonButton label="Notes" buttonType="primary" onClick={onClickNotes} />
            </div>
          )}
        </ol>
      </div>
    </>
  );
};

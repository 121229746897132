import {
  FetchPaymentAdjustmentCodesInterface,
  AddPaymentAdjustmentCodeInterface,
  FetchPaymentAdjustmentCodeByIdInterface,
  UpdatePaymentAdjustmentCodeInterface,
} from '../../interfaces/codesInterfaces';
import { authenticatedRequest } from '../../utils/commonAxios';

import { queryParamGenerator } from '../../utils/commonFunctions';

export const getPaymentAdjustmentCodesAPIRequest = async (searchParams: FetchPaymentAdjustmentCodesInterface) => {
  const queryParams = queryParamGenerator(searchParams?.filters);

  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/system/billing-code-search${queryParams}`,
    'get',
    {}
  );
};

export const addPaymentAdjustmentCodeAPIRequest = async (codeData: AddPaymentAdjustmentCodeInterface) => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/system/billing-code`, 'post', {
    data: { ...codeData },
  });
};

export const getPaymentAdjustmentCodeByIdAPIRequest = async (searchParams: FetchPaymentAdjustmentCodeByIdInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/system/billing-code/${searchParams?.id}`,
    'get',
    {}
  );
};

export const updatePaymentAdjustmentCodeAPIRequest = async (code: UpdatePaymentAdjustmentCodeInterface) => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/system/billing-code/${code?.id}`, 'put', {
    data: { ...code?.codeData },
  });
};

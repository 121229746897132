import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks/hooks';

import { MainHeader } from '../../components/mainHeader/MainHeader';
import { PageTopic } from '../../components/pageTopic/PageTopic';
import { Pagination } from '../../components/pagination/Pagination';
import { TableContent } from '../../components/table/TableContent';
import { EmptyContent } from '../../components/emptyContent/EmptyContent';
import { PrimaryButton } from '../../components/atoms/PrimaryButton';
import { CommonAlert } from '../../components/atoms/Alert';
import { Spinner } from '../../components/atoms/Spinner';

import { FaFileExcel } from 'react-icons/fa';

import {
  clearFetchSecondaryAgingReportResponse,
  getSecondaryAgingReportRequest,
  getSecondaryAgingReportSelector,
} from '../../redux/slices/reports/getSecondaryAgingReportSlice';
import {
  getSecondaryAgingSummaryRequest,
  getSecondaryAgingSummarySelector,
  clearFetchSecondaryAgingSummaryResponse,
} from '../../redux/slices/reports/getSecondaryAginSummarySlice';

import { formatCurrency } from '../../utils/commonFunctions';

const PAGE_SIZE = 20;

const columns = [
  {
    title: 'Insurance Name',
    dataIndex: 'insuranceName',
    key: 'insuranceName',
  },
  {
    title: 'Current',
    dataIndex: 'current',
    key: 'current',
    render: (text: any) => (
      <a className="no-underline text-linkBlue" href="#">
        {formatCurrency(text ?? 0)}
      </a>
    ),
  },
  {
    title: 'Past 1 - 30',
    dataIndex: 'past1To30',
    key: 'past1To30',
    render: (text: any) => (
      <a className="no-underline text-linkBlue" href="#">
        {formatCurrency(text ?? 0)}
      </a>
    ),
  },
  {
    title: 'Past 31 - 60',
    dataIndex: 'past31To60',
    key: 'past31To60',
    render: (text: any) => (
      <a className="no-underline text-linkBlue" href="#">
        {formatCurrency(text ?? 0)}
      </a>
    ),
  },
  {
    title: 'Past 61 - 90',
    dataIndex: 'past61To90',
    key: 'past61To90',
    render: (text: any) => (
      <a className="no-underline text-linkBlue" href="#">
        {formatCurrency(text ?? 0)}
      </a>
    ),
  },
  {
    title: 'Past 91 - 120',
    dataIndex: 'past91To120',
    key: 'past91To120',
    render: (text: any) => (
      <a className="no-underline text-linkBlue" href="#">
        {formatCurrency(text ?? 0)}
      </a>
    ),
  },
  {
    title: 'Past  > 120',
    dataIndex: 'pastAbove120',
    key: 'pastAbove120',
    render: (text: any) => (
      <a className="no-underline text-linkBlue" href="#">
        {formatCurrency(text ?? 0)}
      </a>
    ),
  },
  {
    title: 'Total Balance',
    dataIndex: 'totalBalance',
    key: 'totalBalance',
    render: (text: any) => (
      <a className="no-underline text-linkBlue" href="#">
        {formatCurrency(text ?? 0)}
      </a>
    ),
  },
];

const summaryColumns = [
  {
    title: 'Current',
    dataIndex: 'current',
    key: 'current',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Past 1 - 31',
    dataIndex: 'past1To30',
    key: 'past1To30',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Past 31 - 60',
    dataIndex: 'past31To60',
    key: 'past31To60',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Past 61 - 90',
    dataIndex: 'past61To90',
    key: 'past61To90',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Past 91 - 120',
    dataIndex: 'past91To120',
    key: 'past91To120',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Past  > 120',
    dataIndex: 'pastAbove120',
    key: 'pastAbove120',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Total Balance',
    dataIndex: 'totalBalance',
    key: 'totalBalance',
    render: (text: number) => formatCurrency(text ?? 0),
  },
];

const dummyDataSource = [
  {
    insuranceName: 'ADC 1 WELFARE FUND',
    current: '$109.00',
    past1: '$0.00	',
    past2: '$0.00',
    past3: '$0.00	',
    past4: '$109.00',
    past5: '$0.00',
    totalBalance: '$0.00',
  },
];

const dummySummaryDataSource = [
  {
    current: '$109.00',
    past1: '$0.00	',
    past2: '$0.00',
    past3: '$0.00	',
    past4: '$109.00',
    past5: '$0.00',
    totalBalance: '$0.00',
  },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  {
    id: 'secondaryInsuranceReport',
    label: 'Secondary Insurance Aging',
    status: 'active',
    link: 'secondary-insurance-report',
  },
];

const initialAlertState = {
  color: '',
  message: '',
  error: false,
};

export const SecondaryInsuranceAgingReportPage = () => {
  const dispatch = useAppDispatch();

  const { fetchSecondaryAgingReportData, fetchSecondaryAgingReportStatus, fetchSecondaryAgingReportLoading } =
    useAppSelector(getSecondaryAgingReportSelector);
  const { fetchSecondaryAgingSummaryStatus, fetchSecondaryAgingSummaryData } = useAppSelector(
    getSecondaryAgingSummarySelector
  );

  const [dataSource, setDataSource] = useState<any>([]);
  const [summaryDataSource, setSummaryDataSource] = useState<any[]>([]);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>(initialAlertState);

  useEffect(() => {
    fetchSecondaryAgingData();
    fetchSecondaryAgingSummary();
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  }, []);

  useEffect(() => {
    if (fetchSecondaryAgingReportStatus === 'SUCCESS') {
      setDataSource(fetchSecondaryAgingReportData?.items);
      setCurrentPage(fetchSecondaryAgingReportData?.currentPage);
      setTotalPages(fetchSecondaryAgingReportData?.totalPages);
      dispatch(clearFetchSecondaryAgingReportResponse());
    } else if (fetchSecondaryAgingReportStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj(initialAlertState);
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearFetchSecondaryAgingReportResponse());
    }
  }, [fetchSecondaryAgingReportStatus]);

  useEffect(() => {
    if (fetchSecondaryAgingSummaryStatus === 'SUCCESS') {
      setSummaryDataSource([fetchSecondaryAgingSummaryData]);
      dispatch(clearFetchSecondaryAgingSummaryResponse());
    } else if (fetchSecondaryAgingSummaryStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong when fetching report summary!',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj(initialAlertState);
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearFetchSecondaryAgingSummaryResponse());
    }
  }, [fetchSecondaryAgingSummaryStatus]);

  const onPageChange = (page: any) => {
    setCurrentPage(page - 1);
    fetchSecondaryAgingData(page - 1);
  };

  const fetchSecondaryAgingData = (pageNumber = currentPage) => {
    dispatch(getSecondaryAgingReportRequest({ size: PAGE_SIZE, page: pageNumber }));
  };

  const fetchSecondaryAgingSummary = () => {
    dispatch(getSecondaryAgingSummaryRequest());
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic mainTitle="Secondary Insurance Aging" enablePrimaryButton={false} breadCrumbArr={breadCrumbArr} />

      {visibleAlert && alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}

      <div className="flex justify-end mb-4">
        <div className="flex items-center">
          <label htmlFor="search" className="mr-2 text-gray-600">
            Search:
          </label>
          <input
            type="text"
            className="border border-gray-300 rounded px-2 py-1 mr-2"
            name="search"
            title="Search"
            disabled
          />
          <PrimaryButton
            type="button"
            label="Export to Excel"
            icon={<FaFileExcel style={{ fontSize: '18px' }} />}
            style={{ height: '38px', maxWidth: '100px' }}
          />
        </div>
      </div>

      {fetchSecondaryAgingReportLoading ? (
        <Spinner />
      ) : (
        <>
          {dataSource && dataSource?.length > 0 ? (
            <>
              <TableContent enableActions={false} columns={columns} dataSource={dataSource} />

              {totalPages > 1 && (
                <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
              )}

              <TableContent
                enableActions={false}
                columns={summaryColumns}
                dataSource={summaryDataSource}
                lastRowStyles="font-bold"
                enableLastRowStyles={true}
              />
            </>
          ) : (
            <EmptyContent enableCreateButton={false} mode="VIEW" />
          )}
        </>
      )}
    </div>
  );
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  FetchBankReconcilationInterface,
  FetchBankReconcilationInitialInterface,
} from '../../../interfaces/reportsInterfaces';

const initialState: FetchBankReconcilationInitialInterface = {
  fetchBankReconcilationLoading: false,
  fetchBankReconcilationData: {
    currentPage: 0,
    items: [],
    totalItems: 0,
    totalPages: 0,
  },
  fetchBankReconcilationError: null,
  fetchBankReconcilationStatus: 'IDLE',
};

export const getBankReconcilationRequest: any = createAsyncThunk(
  'reports/bank-reconcilation/get',
  async (reportData: FetchBankReconcilationInterface, thunkAPI: any) => {
    try {
      //   const response: any = await getBankReconcilationAPIRequest(reportData);
      //   return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getBankReconcilationSlice = createSlice({
  name: 'fetchBankReconcilation',
  initialState,
  reducers: {
    clearFetchBankReconcilationResponse: (state) => {
      state.fetchBankReconcilationLoading = false;
      state.fetchBankReconcilationData = { currentPage: 0, items: [], totalItems: 0, totalPages: 0 };
      state.fetchBankReconcilationStatus = 'IDLE';
      state.fetchBankReconcilationError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBankReconcilationRequest.pending, (state, action) => {
        state.fetchBankReconcilationLoading = true;
        state.fetchBankReconcilationStatus = 'PENDING';
      })
      .addCase(getBankReconcilationRequest.fulfilled, (state, action) => {
        state.fetchBankReconcilationLoading = false;
        state.fetchBankReconcilationStatus = 'SUCCESS';
        state.fetchBankReconcilationData = action.payload;
      })
      .addCase(getBankReconcilationRequest.rejected, (state, action) => {
        state.fetchBankReconcilationLoading = false;
        state.fetchBankReconcilationStatus = 'FAILED';
        state.fetchBankReconcilationError = action.payload;
      });
  },
});

export const { clearFetchBankReconcilationResponse } = getBankReconcilationSlice.actions;

export const getBankReconcilationSelector = (state: RootState) => state.GetBankReconcilation;

export default getBankReconcilationSlice.reducer;

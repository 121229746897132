import Axios from 'axios';
import { LoginPageInterface } from '../../interfaces/loginInterface';

export const loginAPIRequest = (userCred: LoginPageInterface, encodedEmail: string) => {
  // console.log('=== userCred ===', userCred);
  const ipAddress = localStorage.getItem('ipAddress') || '';

  return Axios.post(`${process.env.REACT_APP_API_URL}/v1/user/sign-in`, userCred, {
    headers: {
      email: encodedEmail,
      'client-ip': ipAddress,
    },
  });
};

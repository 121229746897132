import moment from 'moment';

export const convertDate = (date: any) => {
  return moment(date).format('YYYY-MM-DD');
};

export const validateEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const isValidEmail = emailRegex.test(email);
  return isValidEmail;
};

export const validatePhoneNumber = (input: string): boolean => {
  const cleaned = input.replace(/[^0-9]/g, ''); // Remove non-numeric characters
  return /^[0-9]{10}$/.test(cleaned); // Check if it has exactly 10 digits
};

export const formatSSN = (input: string): string => {
  // Remove non-numeric characters
  const cleaned = input.replace(/\D/g, '');

  // Format SSN as ###-##-####
  const match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/);
  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`;
  }

  return input;
};

export const formatDate = (date: any): string | null => {
  if (date && moment(date, moment.ISO_8601, true).isValid()) {
    return moment(date).format('YYYY-MM-DD');
  }
  return null;
};

export const formatDatePickerDate = (date: any): Date | null => {
  if (date && moment(date, moment.ISO_8601, true).isValid()) {
    return new Date(moment(date).format('YYYY-MM-DD'));
  }
  return null;
};

export const createViewEligibilityObject = (data: any) => {
  const location = [data?.subscriberCity, data?.subscriberState, data?.subscriberZip].filter(Boolean).join(', ');

  const formattedInsuredAddress = [data?.subscriberAddress1, data?.subscriberAddress2, location].filter(Boolean);

  let obj = {
    insuranceName: data?.insuranceName || '',
    insured: data?.subscriberName || '',
    policyNo: data?.subscriberPolicyId || '',
    groupName: '',
    groupNo: data?.subscriberGroupId,
    insuredDOB: data?.subscriberDOB ? moment(data.subscriberDOB, 'YYYYMMDD').format('MM/DD/YYYY') : '',
    insuredGender: data?.subscriberGender
      ? data.subscriberGender === 'M'
        ? 'Male'
        : data.subscriberGender === 'F'
        ? 'Female'
        : 'Other'
      : '',
    // insuredAddressPOBox: data?.edi271N3Dto?.n301,
    insuredAddress: formattedInsuredAddress,
    eligibilityName: data?.dateForMap?.[0] || '',
    eligibilityDate: data?.dateMap?.[0] ? moment(data?.dateMap?.[0], 'YYYYMMDD').format('MM/DD/YYYY') : '',
    eligibilityBegin: '',
    errorMessage: data?.edi271AAADto?.aaa01Message,
    secondaryErrorMessage: data?.edi271AAADto?.aaa03,
  };

  const handledArr = handleViewEligibilityArray(data, data?.edi271EBList);

  return { mainObject: obj, detailList: handledArr };
};

export const handleViewEligibilityArray = (data: any, ediList: any) => {
  let finalArr: any = [];

  for (let index = 0; index < ediList.length; index++) {
    const element = ediList[index];
    // let obj = {
    //   npiType: element?.eb02,
    //   coverage: element?.eb01,
    //   groupName: element?.eb05,
    //   individualName: '',
    //   postBox: '',
    //   description: element?.eb04 || element?.eb01 ? element?.eb04 + ' ' + element?.eb01 : '',
    //   postalCode: '',
    //   visitDetails: element?.eb06,
    //   amount: formatToTwoDecimalPoints(element?.eb07),
    // };
    let planNameDescription = '';
    if (element?.eb07 === '1') {
      planNameDescription = `${element.eb07} - Beneficiary insured due to disability`;
    } else if (element?.eb05) {
      planNameDescription = element.eb05;
    }

    let dateName = '';
    const dateForMapValue = data?.dateForMap?.[element?.ebId];
    const dateMapValue = data?.dateMap?.[element?.ebId];

    if (dateForMapValue === 'Plan') {
      if (dateMapValue && dateMapValue.includes('-')) {
        dateName = 'Plan Date From';
      } else {
        dateName = 'Plan Date';
      }
    } else {
      dateName = dateForMapValue;
    }

    let obj = {
      planNameDescription: planNameDescription,
      dateName: dateName,
      date: formatViewEligibilityDateFormat(dateMapValue),
      coverage: element?.eb01,
      visitDetails: element?.eb06,
      amount: formatToTwoDecimalPoints(element?.eb07),
      description: [element?.eb04, element?.eb01, element?.eb08 === 'R' ? 'Other or Additional Payer' : '']
        .filter(Boolean)
        .join(' '),
      payerName: data?.lsNameMap?.[element?.ebId] || '',
      payerAddress1: data?.lsAddress1Map?.[element?.ebId] || '',
      payerAddress2: data?.lsAddress2Map?.[element?.ebId] || '',
      // payerCity: data?.lsCityMap?.[element?.ebId] || '',
      // payerState: data?.lsStateMap?.[element?.ebId] || '',
      // payerZip: data?.lsZipMap?.[element?.ebId] || '',
      location: [data?.lsCityMap?.[element?.ebId], data?.lsStateMap?.[element?.ebId], data?.lsZipMap?.[element?.ebId]]
        .filter(Boolean)
        .join(' '),
      phone: data?.permap?.[element?.ebId]?.per04 || '',
      website: data?.permap?.[element?.ebId]?.per06 || '',
    };
    // console.log('=== obj ===', obj);
    if (Object.values(obj).some((value) => value !== '' && value !== undefined && value !== null)) {
      finalArr[finalArr?.length] = obj;
    }
  }

  return finalArr;
};

const formatViewEligibilityDateFormat = (date: string) => {
  if (!date) return '';
  if (date.includes('-')) {
    const [startDate, endDate] = date.split('-');
    const formattedStart = moment(startDate, 'YYYYMMDD').format('MM/DD/YYYY');
    const formattedEnd = moment(endDate, 'YYYYMMDD').format('MM/DD/YYYY');
    return `${formattedStart} To ${formattedEnd}`;
  }

  return moment(date, 'YYYYMMDD').format('MM/DD/YYYY');
};

// Utility function to format string number to 2 decimal points
const formatToTwoDecimalPoints = (numStr: string) => {
  if (numStr === '') {
    return '';
  }
  const num = parseFloat(numStr);
  return !isNaN(num) ? num.toFixed(2) : '';
};

export const handlePatientInsurance = (data: any) => {
  let handledArr: any = [];
  for (let index = 0; index < data.length; index++) {
    const element = data[index];
    let obj = {
      id: element?.id,
      name: element?.insurance,
    };
    handledArr[handledArr.length] = obj;
  }

  return handledArr;
};

export const validSSNLength = 9;
export const validNPILength = 10;
export const validTaxonomyLength = 10;
export const validZipCodeLength = 5;
export const validPhoneFaxLength = 10;
export const validTaxIdLength = 9;
export const validDateFormat = 'MM/DD/YYYY';

export const isValidDate = (year: number, month: number, day: number) => {
  const date = new Date(year, month - 1, day);
  return date.getFullYear() === year && date.getMonth() + 1 === month && date.getDate() === day;
};

export const daysInMonth = (year: number, month: number): number => {
  return new Date(year, month, 0).getDate();
};

export const formatPartialDate = (input: string) => {
  const charToInsert = '0';
  let cleanedInput = input.replace(/[^0-9]/g, '');
  let formattedDate = '';

  if (cleanedInput.length > 0) {
    let year = cleanedInput.substring(0, 4);
    formattedDate += year; // Year
    if (cleanedInput.length >= 5) {
      formattedDate += '-';
      let month = cleanedInput.substring(4, 6);

      // Adjust the month if it's greater than 12
      if (parseInt(month) > 12) {
        cleanedInput = cleanedInput.substring(0, 4) + charToInsert + cleanedInput.substring(4);
        month = cleanedInput.substring(4, 6); // Re-extract the month after adjustment;
      }

      formattedDate += month; // Month

      if (cleanedInput.length >= 7) {
        formattedDate += '-';
        let day = cleanedInput.substring(6, 8);
        // console.log('=== day ===', day);

        // Validate the day for the given month and year
        let validDays = daysInMonth(parseInt(year), parseInt(month));
        if (parseInt(day) > validDays) {
          day = charToInsert + day[0]; // Adjust day if invalid (e.g., change '32' to '02')
        }
        formattedDate += day; // Day
      }
    }
  }

  return formattedDate;
};

export const convertingToDropdownArr = (arr: any) => {
  const finalArr = [];
  for (let index = 0; index < arr.length; index++) {
    const element = arr[index];
    const obj: any = {
      id: Object.values(element)[0],
      name: Object.values(element)[1],
    };
    finalArr[finalArr.length] = obj;
  }
  return finalArr;
};

export const formatCurrency = (value: number | string): string => {
  const parsedValue = typeof value === 'string' ? Number(value) : value;
  if (isNaN(parsedValue)) return '$0.00';

  return `$${new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(parsedValue)}`;
};

export const queryParamGenerator = (params: any): string => {
  const paramList = Object.keys(params).map((key) => {
    const encodedValue = params[key] === undefined || params[key] === null ? '' : encodeURIComponent(params[key]);
    return `${key}=${encodedValue}`;
  });

  return paramList.length > 0 ? `?${paramList.join('&')}` : '';
};

import { FaFileExcel } from 'react-icons/fa';
import { VisitsActionAreaProps } from '../../interfaces/billingInterface';
import { Badge } from '../atoms/Badge';
import { PrimaryButton } from '../atoms/PrimaryButton';
import './tableActionArea.css';

export const VisitsTableActionArea: React.FC<VisitsActionAreaProps> = ({ totalVisits, totalCharges, onExport }) => {
  return (
    <>
      <div className="table-action-area">
        <div className="table-action-area__left-actions">
          <div className="flex gap-2">
            <div>Total Visits</div>
            <Badge value={totalVisits} />
          </div>

          <div className="flex gap-2">
            <div>Total Charges</div>
            <Badge value={totalCharges} />
          </div>
        </div>
        <div className="table-action-area__right-actions">
          <PrimaryButton
            label="Export to Excel"
            icon={<FaFileExcel style={{ fontSize: '18px' }} />}
            style={{ height: '40px', maxWidth: '100px' }}
            onClick={onExport}
          />
        </div>
      </div>
    </>
  );
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  FetchPaymentAdjustmentCodesInterface,
  FetchPaymentAdjustmentCodesInitialInterface,
} from '../../../interfaces/codesInterfaces';
import { getPaymentAdjustmentCodesAPIRequest } from '../../../api/account/paymentAdjustment';

const initialState: FetchPaymentAdjustmentCodesInitialInterface = {
  fetchPaymentAdjustmentCodesLoading: false,
  fetchPaymentAdjustmentCodesData: {
    currentPage: 0,
    items: [],
    totalItems: 0,
    totalPages: 0,
  },
  fetchPaymentAdjustmentCodesError: null,
  fetchPaymentAdjustmentCodesStatus: 'IDLE',
};

export const getPaymentAdjustmentCodesRequest: any = createAsyncThunk(
  'payment-adjustment-codes/get',
  async (searchParams: FetchPaymentAdjustmentCodesInterface, thunkAPI: any) => {
    try {
      const response: any = await getPaymentAdjustmentCodesAPIRequest(searchParams);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getPaymentAdjustmentCodesSlice = createSlice({
  name: 'fetchPaymentAdjustmentCodes',
  initialState,
  reducers: {
    clearFetchPaymentAdjustmentCodesResponse: (state) => {
      state.fetchPaymentAdjustmentCodesLoading = false;
      state.fetchPaymentAdjustmentCodesData = { currentPage: 0, items: [], totalItems: 0, totalPages: 0 };
      state.fetchPaymentAdjustmentCodesStatus = 'IDLE';
      state.fetchPaymentAdjustmentCodesError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentAdjustmentCodesRequest.pending, (state, action) => {
        state.fetchPaymentAdjustmentCodesLoading = true;
        state.fetchPaymentAdjustmentCodesStatus = 'PENDING';
      })
      .addCase(getPaymentAdjustmentCodesRequest.fulfilled, (state, action) => {
        state.fetchPaymentAdjustmentCodesLoading = false;
        state.fetchPaymentAdjustmentCodesStatus = 'SUCCESS';
        state.fetchPaymentAdjustmentCodesData = action.payload;
      })
      .addCase(getPaymentAdjustmentCodesRequest.rejected, (state, action) => {
        state.fetchPaymentAdjustmentCodesLoading = false;
        state.fetchPaymentAdjustmentCodesStatus = 'FAILED';
        state.fetchPaymentAdjustmentCodesError = action.payload;
      });
  },
});

export const { clearFetchPaymentAdjustmentCodesResponse } = getPaymentAdjustmentCodesSlice.actions;

export const getPaymentAdjustmentCodesSelector = (state: RootState) => state.GetPaymentAdjustmentCodes;

export default getPaymentAdjustmentCodesSlice.reducer;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { FetchSystemBillingCodeTypeListInitialInterface } from '../../../interfaces/systemInterfaces';
import { getSystemBillingCodeTypeListAPIRequest } from '../../../api/system/code';

const initialState: FetchSystemBillingCodeTypeListInitialInterface = {
  fetchSystemBillingCodeTypeListLoading: false,
  fetchSystemBillingCodeTypeListData: [],
  fetchSystemBillingCodeTypeListError: null,
  fetchSystemBillingCodeTypeListStatus: 'IDLE',
};

export const getSystemBillingCodeTypeListRequest: any = createAsyncThunk(
  'system/billing-code-types/get',
  async (thunkAPI: any) => {
    try {
      const response: any = await getSystemBillingCodeTypeListAPIRequest();
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getSystemBillingCodeTypeListSlice = createSlice({
  name: 'fetchSystemBillingCodeTypes',
  initialState,
  reducers: {
    clearFetchSystemBillingCodeTypesResponse: (state) => {
      state.fetchSystemBillingCodeTypeListLoading = false;
      state.fetchSystemBillingCodeTypeListStatus = 'IDLE';
      state.fetchSystemBillingCodeTypeListData = [];
      state.fetchSystemBillingCodeTypeListError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSystemBillingCodeTypeListRequest.pending, (state, action) => {
        state.fetchSystemBillingCodeTypeListLoading = true;
        state.fetchSystemBillingCodeTypeListStatus = 'PENDING';
      })
      .addCase(getSystemBillingCodeTypeListRequest.fulfilled, (state, action) => {
        state.fetchSystemBillingCodeTypeListLoading = false;
        state.fetchSystemBillingCodeTypeListData = action.payload;
        state.fetchSystemBillingCodeTypeListStatus = 'SUCCESS';
      })
      .addCase(getSystemBillingCodeTypeListRequest.rejected, (state, action) => {
        state.fetchSystemBillingCodeTypeListLoading = false;
        state.fetchSystemBillingCodeTypeListError = action.payload;
        state.fetchSystemBillingCodeTypeListStatus = 'FAILED';
      });
  },
});

export const { clearFetchSystemBillingCodeTypesResponse } = getSystemBillingCodeTypeListSlice.actions;

export const getSystemBillingCodeTypeListSelector = (state: RootState) => state.GetBillingCodeTypes;

export default getSystemBillingCodeTypeListSlice.reducer;

import {
  AddCPTCodeInterface,
  AddFeeAmountInterface,
  AddICDCodeInterface,
  AddModifierInterface,
  EditCPTCodeInterface,
  GetCPTCodesByIdInterface,
  GetCPTCodesInterface,
  GetFeeInterface,
  GetICDCodesInterface,
  GetModifierByIdInterface,
  UpdateModifierInterface,
} from '../../interfaces/systemInterfaces';
import { authenticatedRequest } from '../../utils/commonAxios';

// ICD

export const getICDCodesAPIRequest = async (codeData: GetICDCodesInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/system/search-icd-codes?page=${codeData?.page}&size=${codeData?.size}&code=${codeData?.code}&desc=${codeData?.desc}`,
    'get',
    {}
  );
};

export const addICDCodeAPIRequest = async (code: AddICDCodeInterface) => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/system/create-icd-code`, 'post', {
    data: { ...code },
  });
};

// CPT

export const getCPTCodesAPIRequest = async (codeData: GetCPTCodesInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/system/search-cpt-codes?page=${codeData?.page}&size=${codeData?.size}&code=${codeData?.code}&desc=${codeData?.desc}`,
    'get',
    {}
  );
};

export const addCPTCodeAPIRequest = async (code: AddCPTCodeInterface) => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/system/create-cpt-code`, 'post', {
    data: code,
  });
};

export const getCPTCodeByIdAPIRequest = async (code: GetCPTCodesByIdInterface) => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/system/view-cpt-code/${code?.id}`, 'get', {});
};

export const addFeeAmountAPIRequest = async (code: AddFeeAmountInterface) => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/system/create-fee-amount`, 'post', {
    data: code,
  });
};

export const getFeeAmountAPIRequest = async (code: GetFeeInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/system/search-fee-amount/cpt/${code?.id}?page=${code?.page}&size=${code?.size}`,
    'get',
    {}
  );
};

export const editCPTCodeAPIRequest = async (code: EditCPTCodeInterface) => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/system/edit-cpt-code/${code?.id}`, 'put', {
    data: code,
  });
};

// Modifier

export const getModifiersAPIRequest = async (codeData: GetICDCodesInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/system/search-modifiers?page=${codeData?.page}&size=${codeData?.size}&code=${codeData?.code}&desc=${codeData?.desc}`,
    'get',
    {}
  );
};

export const addModifiersAPIRequest = async (code: AddModifierInterface) => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/system/create-modifier`, 'post', {
    data: code,
  });
};

export const updateModifiersAPIRequest = async (code: UpdateModifierInterface) => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/system/edit-modifier/${code?.id}`, 'put', {
    data: {
      code: code?.code,
      desc: code?.desc,
    },
  });
};

export const getModifierByIdAPIRequest = async (codeData: GetModifierByIdInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/system/view-modifier/${codeData?.id}`,
    'get',
    {}
  );
};

export const deleteModifierByIdAPIRequest = async (codeData: GetModifierByIdInterface) => {
  return await authenticatedRequest(
    `${process.env.REACT_APP_API_URL}/v1/system/remove-modifier/${codeData?.id}`,
    'delete',
    {}
  );
};

export const getModifierListAPIRequest = async () => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/system/modifier-list`, 'get', {});
};

export const getSystemBillingCodeTypeListAPIRequest = async () => {
  return await authenticatedRequest(`${process.env.REACT_APP_API_URL}/v1/system/billing-code-types`, 'get', {});
};

import { userTitleType } from '../../../constants/systemConstants';
import { employmentStatus, sexStatus, userStatus } from '../../../constants/userConstants';
import { useAppSelector } from '../../../hooks/storeHooks/hooks';
import { DemograhicFormProps } from '../../../interfaces/patientInterface';
import { getSystemFacilitiesSelector } from '../../../redux/slices/system/getSystemFacilitiesSlice';
import { getSystemProvidersSelector } from '../../../redux/slices/system/getSystemProvidersSlice';
import { formatSSN } from '../../../utils/commonFunctions';
import { Calendar } from '../../atoms/Calendar';
import { Checkbox } from '../../atoms/Checkbox';
import { InputField } from '../../atoms/InputField';
import { SecondaryButton } from '../../atoms/SecondaryButton';
import { SelectInput } from '../../atoms/SelectInput';
import { DatePickerField } from '../../atoms/DatePickerField';
import { formatDatePickerDate } from '../../../utils/commonFunctions';

export const Demographics: React.FC<DemograhicFormProps> = ({
  onChange,
  onSelectDOB,
  demographicData,
  mode,
  errorObject,
}) => {
  // console.log('=== demographicData ===', demographicData, demographicData?.title);

  const { systemFacilityData } = useAppSelector(getSystemFacilitiesSelector);
  const { systemProvidersProviderData } = useAppSelector(getSystemProvidersSelector);

  const handleSSN = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedValue = formatSSN(e.target.value);
    onChange({ ...e, target: { ...e.target, value: formattedValue } });
  };

  return (
    <>
      <div style={{ marginBottom: '10px', fontWeight: 'bold', fontSize: '18px' }}>Demographics</div>
      {/* <hr /> */}
      <h6 className="sub-heading" style={{ marginTop: '5px' }}>
        General Details
      </h6>
      <hr />
      <div className="grid md:grid-cols-3 gap-4 mt-6 mb-10">
        <SelectInput
          label={'Facility'}
          name="facilityId"
          onChange={onChange}
          options={systemFacilityData}
          value={demographicData && demographicData?.facilityId ? demographicData?.facilityId.toString() : undefined}
          disabled={mode === 'VIEW' ? true : false}
          required={true}
        />

        {mode === 'CREATE' && (
          <SelectInput
            label={'Provider'}
            name="providerId"
            onChange={onChange}
            options={systemProvidersProviderData}
            // disabled={mode === 'VIEW' ? true : false}
            value={demographicData && demographicData?.providerId ? demographicData?.providerId.toString() : undefined}
            required={true}
          />
        )}
        <SelectInput
          label={'Status'}
          name="status"
          onChange={onChange}
          options={userStatus}
          disabled={mode === 'VIEW' ? true : false}
          value={demographicData && demographicData?.status ? demographicData?.status.toString() : undefined}
        />
      </div>
      <h6 className="sub-heading">Personal Details</h6>
      <hr />
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <div className="col-span-1">
          <div className="flex gap-2">
            <div className="w-1/3">
              <SelectInput
                label="Title"
                name="title"
                onChange={onChange}
                options={userTitleType}
                value={demographicData && demographicData?.title ? demographicData?.title.toString() : undefined}
                disabled={mode === 'VIEW' ? true : false}
              />
            </div>
            <div className="w-2/3">
              <InputField
                label="First Name"
                name="firstName"
                onChange={onChange}
                disabled={mode === 'VIEW' ? true : false}
                value={demographicData?.firstName ?? undefined}
                error={errorObject && errorObject?.firstName}
                errorMessage={errorObject && errorObject?.firstName}
                required={true}
              />
            </div>
          </div>
        </div>
        <div className="col-span-1">
          <div className="flex gap-2">
            <div className="w-11/12">
              <InputField
                label="Middle Name"
                name="middleName"
                onChange={onChange}
                disabled={mode === 'VIEW' ? true : false}
                value={demographicData?.middleName ?? undefined}
              />
            </div>
            {/* <div className="w-1/12 mt-7">
              <SecondaryButton />
            </div> */}
          </div>
        </div>
        <div className="col-span-1">
          <InputField
            label="Last Name"
            name="lastName"
            onChange={onChange}
            disabled={mode === 'VIEW' ? true : false}
            value={demographicData?.lastName ?? undefined}
            error={errorObject && errorObject?.lastName}
            errorMessage={errorObject && errorObject?.lastName}
            required={true}
          />
        </div>
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <div className="col-span-1">
          <InputField
            label="Suffix"
            name="suffix"
            onChange={onChange}
            disabled={mode === 'VIEW' ? true : false}
            value={demographicData?.suffix ?? undefined}
            tooltip="e.g. I, II, III, IV, Jr, Sr"
          />
        </div>
        <div className="col-span-1">
          {/* <InputField label="Date of Birth" name="dob" onChange={onChange} /> */}
          {/* <Calendar
            onSelectedDateChanged={onSelectDOB}
            name="dob"
            label="Date of Birth"
            disabled={mode === 'VIEW' ? true : false}
            defaultDate={demographicData?.dob ? new Date(demographicData?.dob) : undefined}
            error={errorObject && errorObject?.dob}
            errorMessage={errorObject && errorObject?.dob}
            required={true}
          /> */}
          <DatePickerField
            label="Date of Birth"
            name="dob"
            // selectedDate={licenseFormEditData?.effectiveDate ? new Date(licenseFormEditData?.effectiveDate) : null}
            selectedDate={formatDatePickerDate(demographicData?.dob)}
            onChange={onSelectDOB}
            required={true}
            disabled={mode === 'VIEW' ? true : false}
          />
        </div>
        <div className="col-span-1">
          <SelectInput
            label="Gender"
            name="sexStatus"
            onChange={onChange}
            options={sexStatus}
            disabled={mode === 'VIEW' ? true : false}
            value={demographicData && demographicData?.sexStatus ? demographicData?.sexStatus.toString() : undefined}
            error={errorObject && errorObject?.sexStatus}
            errorMessage={errorObject && errorObject?.sexStatus}
            required={true}
          />
        </div>
      </div>
      <div className="grid md:grid-cols-3 gap-4 mt-6">
        <InputField
          label="SSN"
          name="ssn"
          onChange={onChange}
          disabled={mode === 'VIEW' ? true : false}
          error={errorObject && errorObject?.ssn}
          errorMessage={errorObject && errorObject?.ssn}
          value={demographicData?.ssn ?? undefined}
          tooltip="Character length must be 9 digits"
        />
      </div>
      <h6 className="sub-heading">Contact Details</h6>
      <hr />
      <div className="grid md:grid-cols-3 gap-4 my-6">
        <InputField
          label="Email"
          name="email"
          value={demographicData?.email ?? undefined}
          onChange={onChange}
          disabled={mode === 'VIEW' ? true : false}
          error={errorObject && errorObject?.email}
          errorMessage={errorObject && errorObject?.email}
        />
        <InputField
          label="Home Phone"
          name="homePhone"
          value={demographicData?.homePhone ?? undefined}
          onChange={onChange}
          disabled={mode === 'VIEW' ? true : false}
          error={errorObject && errorObject?.homePhone}
          errorMessage={errorObject && errorObject?.homePhone}
          tooltip="10-digit mobile number required"
        />
        <InputField
          label="Cell Phone"
          name="cellPhone"
          value={demographicData?.cellPhone ?? undefined}
          onChange={onChange}
          disabled={mode === 'VIEW' ? true : false}
          error={errorObject && errorObject?.cellPhone}
          errorMessage={errorObject && errorObject?.cellPhone}
          tooltip="10-digit mobile number required"
        />
      </div>
      <hr />
      <div className="grid md:grid-cols-4 gap-4 mt-6">
        <InputField
          label="Fax"
          name="fax"
          onChange={onChange}
          disabled={mode === 'VIEW' ? true : false}
          value={demographicData?.fax ?? undefined}
          tooltip="10-digit fax number required"
        />
        {/* <InputField /> */}
        {/* <SelectInput /> */}
        <SelectInput
          label="Employment Status"
          name="employmentStatus"
          onChange={onChange}
          options={employmentStatus}
          disabled={mode === 'VIEW' ? true : false}
          value={
            demographicData && demographicData?.employmentStatus
              ? demographicData?.employmentStatus.toString()
              : undefined
          }
          error={errorObject && errorObject?.employmentStatus}
          errorMessage={errorObject && errorObject?.employmentStatus}
        />
      </div>
    </>
  );
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { FetchQBHSInitialInterface, FetchQBHSInterface } from '../../../interfaces/reportsInterfaces';

const initialState: FetchQBHSInitialInterface = {
  fetchQBHSLoading: false,
  fetchQBHSData: {
    currentPage: 0,
    items: [],
    totalItems: 0,
    totalPages: 0,
  },
  fetchQBHSError: null,
  fetchQBHSStatus: 'IDLE',
};

export const getQBHSRequest: any = createAsyncThunk(
  'reports/QBHS/get',
  async (reportData: FetchQBHSInterface, thunkAPI: any) => {
    try {
      //   const response: any = await getQBHSAPIRequest(reportData);
      //   return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getQBHSSlice = createSlice({
  name: 'fetchQBHS',
  initialState,
  reducers: {
    clearQBHSResponse: (state) => {
      state.fetchQBHSLoading = false;
      state.fetchQBHSData = { currentPage: 0, items: [], totalItems: 0, totalPages: 0 };
      state.fetchQBHSStatus = 'IDLE';
      state.fetchQBHSError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getQBHSRequest.pending, (state, action) => {
        state.fetchQBHSLoading = true;
        state.fetchQBHSStatus = 'PENDING';
      })
      .addCase(getQBHSRequest.fulfilled, (state, action) => {
        state.fetchQBHSLoading = false;
        state.fetchQBHSStatus = 'SUCCESS';
        state.fetchQBHSData = action.payload;
      })
      .addCase(getQBHSRequest.rejected, (state, action) => {
        state.fetchQBHSLoading = false;
        state.fetchQBHSStatus = 'FAILED';
        state.fetchQBHSError = action.payload;
      });
  },
});

export const { clearQBHSResponse } = getQBHSSlice.actions;

export const getQBHSSelector = (state: RootState) => state.GetQBHS;

export default getQBHSSlice.reducer;

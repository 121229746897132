import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  FetchPrimaryAgingReportInterface,
  FetchPrimaryAgingReportInitialInterface,
} from '../../../interfaces/reportsInterfaces';
import { getPrimaryAgingReportAPIRequest } from '../../../api/reports/accountRecievable';

const initialState: FetchPrimaryAgingReportInitialInterface = {
  fetchPrimaryAgingReportLoading: false,
  fetchPrimaryAgingReportData: {
    currentPage: 0,
    items: [],
    totalItems: 0,
    totalPages: 0,
  },
  fetchPrimaryAgingReportError: null,
  fetchPrimaryAgingReportStatus: 'IDLE',
};

export const getPrimaryAgingReportRequest: any = createAsyncThunk(
  'reports/primaryAging/get',
  async (reportData: FetchPrimaryAgingReportInterface, thunkAPI: any) => {
    try {
      const response: any = await getPrimaryAgingReportAPIRequest(reportData);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getPrimaryAgingReportSlice = createSlice({
  name: 'fetchPrimaryAgingReport',
  initialState,
  reducers: {
    clearFetchPrimaryAgingReportResponse: (state) => {
      state.fetchPrimaryAgingReportLoading = false;
      state.fetchPrimaryAgingReportData = { currentPage: 0, items: [], totalItems: 0, totalPages: 0 };
      state.fetchPrimaryAgingReportStatus = 'IDLE';
      state.fetchPrimaryAgingReportError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPrimaryAgingReportRequest.pending, (state, action) => {
        state.fetchPrimaryAgingReportLoading = true;
        state.fetchPrimaryAgingReportStatus = 'PENDING';
      })
      .addCase(getPrimaryAgingReportRequest.fulfilled, (state, action) => {
        state.fetchPrimaryAgingReportLoading = false;
        state.fetchPrimaryAgingReportStatus = 'SUCCESS';
        state.fetchPrimaryAgingReportData = action.payload;
      })
      .addCase(getPrimaryAgingReportRequest.rejected, (state, action) => {
        state.fetchPrimaryAgingReportLoading = false;
        state.fetchPrimaryAgingReportStatus = 'FAILED';
        state.fetchPrimaryAgingReportError = action.payload;
      });
  },
});

export const { clearFetchPrimaryAgingReportResponse } = getPrimaryAgingReportSlice.actions;

export const getPrimaryAgingReportSelector = (state: RootState) => state.GetPrimaryAgingReport;

export default getPrimaryAgingReportSlice.reducer;

import { useEffect, useState } from 'react';
import { MainHeader } from '../../components/mainHeader/MainHeader';
import { PageTopic } from '../../components/pageTopic/PageTopic';
import { Pagination } from '../../components/pagination/Pagination';
import { TableContent } from '../../components/table/TableContent';
import { TableAdvanceSearch } from '../../components/tableSearch/TableAdvanceSearch';
import { RightSheetLarge } from '../../components/rightSheet/RightSheetLarge';
import { RightSheet } from '../../components/rightSheet/RightSheet';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks/hooks';
import { useLocalStorage } from '../../hooks/localStorageHook';
import moment from 'moment';
import { Alert } from 'flowbite-react';
import { CommonAlert } from '../../components/atoms/Alert';
import { Profile } from '../../components/list/providers/Profile';
import { ProviderProfession } from '../../components/list/providers/profession/Professional';
import { ProviderLicense } from '../../components/list/providers/license/License';
import { ProviderFacilities } from '../../components/list/providers/facilities/ProviderFacilities';
import { ProviderDocuments } from '../../components/list/providers/documents/Documents';
import {
  getProvidersRequest,
  getProvidersSelector,
  clearFetchProvidersResponse,
} from '../../redux/slices/provider/getProviderSlice';
import {
  addProviderRequest,
  addProviderSelector,
  clearAddProviderResponse,
} from '../../redux/slices/provider/addProviderSlice';
import { Spinner } from '../../components/atoms/Spinner';
import { EmptyContent } from '../../components/emptyContent/EmptyContent';
import {
  clearFetchProviderByIdResponse,
  getProviderByIdRequest,
  getProviderByIdSelector,
} from '../../redux/slices/provider/getProviderByIdSlice';
import {
  clearEditProviderProfileResponse,
  editProviderProfileRequest,
  editProviderProfileSelector,
} from '../../redux/slices/provider/editProviderProfileSlice';
import { convertDate, validDateFormat } from '../../utils/commonFunctions';
import { createProviderProfessionValidation, createProviderValidation } from '../../utils/providerValidation';
import { formatDate } from '../../utils/commonFunctions';
import { ProviderAddress } from '../../components/list/providers/address/ProviderAddress';
import {
  editProviderProfessionalRequest,
  clearEditProviderProfessionalResponse,
  editProviderProfessionalalSelector,
} from '../../redux/slices/provider/editProviderProfessionSlice';
import { ProviderCarriers } from '../../components/list/providers/carriers/ProviderCarriers';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { getSystemProvidersRequest } from '../../redux/slices/system/getSystemProvidersSlice';

const PAGE_SIZE = 10;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName',
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (text: string) => (moment(text).isValid() ? moment(text).format(validDateFormat) : ''),
  },
];

const stepperData = [
  { id: 1, label: 'Profile', requestParam: 'profile' },
  { id: 2, label: 'Address', requestParam: 'address' },
  { id: 3, label: 'Professional', requestParam: 'professional' },
  { id: 4, label: 'License', requestParam: 'license' },
  { id: 5, label: 'Carriers', requestParam: 'carrier' },
  { id: 6, label: 'Facilities', requestParam: 'facility' },
  { id: 7, label: 'Documents', requestParam: 'documents' },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  { id: 'provide', label: 'Providers', status: 'active', link: 'provider' },
];

export const ProvidersPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const getId = query.get('id');

  const { status, providerData, loading } = useAppSelector(getProvidersSelector);
  const { addProviderStatus, addProviderLoading } = useAppSelector(addProviderSelector);
  const { fetchProviderByIdStatus, fetchProviderByIdData, fetchProviderByIdLoading } =
    useAppSelector(getProviderByIdSelector);
  const { editProviderProfileStatus } = useAppSelector(editProviderProfileSelector);

  const { editProviderProfessionalStatus } = useAppSelector(editProviderProfessionalalSelector);

  const [visibleModal, setVisibleModal] = useState(false);
  const [createFormData, setCreateFormData] = useState<any>({});
  const [createProfessionalFormData, setCreateProfessionalFormData] = useState<any>({});
  const [currentPage, setCurrentPage] = useState(0);
  const [dataSource, setDataSource] = useState<any>([]);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertObj, setAlertObj] = useState({
    color: '',
    message: '',
    error: false,
  });
  const [selectedChild, setSelectedChild] = useLocalStorage('selectedProviderChild', 1);
  const [mode, setMode] = useLocalStorage('providerMode', 'CREATE');
  const [selectedToEdit, setSelectedToEdit] = useState<any>({});
  const [selectedSection, setSelectedSection] = useLocalStorage('selectedProviderSection', '');
  const [stepperId, setStepperId] = useLocalStorage('currentProviderStepperId', 1);
  const [visibleEditForm, setVisibleEditForm] = useLocalStorage('isVisibleProviderEditForm', false);
  const [selectedProviderId, setSelectedProviderId] = useLocalStorage('selectedProviderId', null);
  const [errorObj, setErrorObj] = useState<any>({});
  const [totalPages, setTotalPages] = useState(0);
  const [enableFormAlert, setEnableFormAlert] = useState(false);
  const [formAlertDetails, setformAlertDetails] = useState<any>({});
  const [fetchingLoading, setFetchingLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>('');

  // Initial fetching
  useEffect(() => {
    // fetchProviders();
    if (visibleEditForm && selectedProviderId) {
      setStepperId(stepperId);
      setSelectedChild(selectedChild);
      setSelectedSection(selectedSection);
      setSelectedProviderId(selectedProviderId);
      setMode(mode);
      fetchProviderById(selectedProviderId, selectedSection);
    } else if (!visibleEditForm || (visibleEditForm && !selectedProviderId)) {
      setVisibleEditForm(false);
      setStepperId(1);
    }
    if (getId) {
      onEditQueryParams(getId);
    }
    return () => {
      onClearState();
    };
  }, []);

  useEffect(() => {
    fetchProviders(PAGE_SIZE, 0, searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    return () => {
      setStepperId(1);
      setSelectedChild(1);
      setMode('CREATE');
      setSelectedSection('');
      setSelectedProviderId(null);
      setVisibleEditForm(false);
    };
  }, [navigate]);

  // List fetch success
  useEffect(() => {
    if (status === 'SUCCESS') {
      setDataSource(providerData?.items);
      setCurrentPage(providerData?.currentPage);
      setTotalPages(providerData?.totalPages);
      dispatch(clearFetchProvidersResponse());
    } else if (status === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      dispatch(clearFetchProvidersResponse());
    }
  }, [status]);

  // Create success
  useEffect(() => {
    if (addProviderStatus === 'SUCCESS') {
      setAlertObj({
        color: 'success',
        message: 'Successfully created !',
        error: false,
      });
      setVisibleAlert(true);
      setVisibleModal(false);
      fetchProviders();
      getDropdwonData();
      setTimeout(() => {
        setCreateFormData({});
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setEnableFormAlert(false);
        setformAlertDetails({});
      }, 3000);
    } else if (addProviderStatus === 'FAILED') {
      setformAlertDetails({ color: 'failure', message: 'Something went wrong !' });
      setEnableFormAlert(true);
      setTimeout(() => {
        setEnableFormAlert(false);
        setformAlertDetails({});
      }, 3000);
    }

    dispatch(clearAddProviderResponse());
  }, [addProviderStatus]);

  // Provider by ID success
  useEffect(() => {
    if (fetchProviderByIdStatus === 'SUCCESS') {
      if (selectedSection === 'profile') {
        setCreateFormData(fetchProviderByIdData);
        setVisibleEditForm(true);
      } else if (selectedSection === 'professional') {
        setCreateProfessionalFormData(fetchProviderByIdData);
      }
      setFetchingLoading(false);
    } else if (fetchProviderByIdStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong !',
        error: false,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
      }, 3000);
      setFetchingLoading(false);
    }
    dispatch(clearFetchProviderByIdResponse());
  }, [fetchProviderByIdStatus, selectedSection]);

  //Edit success
  useEffect(() => {
    //console.log('editProviderProfileStatus', editProviderProfileStatus);
    if (editProviderProfileStatus === 'SUCCESS') {
      setformAlertDetails({ color: 'success', message: 'Successfully updated !' });
      setEnableFormAlert(true);
      setFetchingLoading(true);
      fetchProviderById(null, 'profile');
      getDropdwonData();
      setTimeout(() => {
        setEnableFormAlert(false);
        setformAlertDetails({});
      }, 3000);
      dispatch(clearEditProviderProfileResponse());
    } else if (editProviderProfileStatus === 'FAILED') {
      setformAlertDetails({ color: 'failure', message: 'Something went wrong !' });
      setEnableFormAlert(true);
      setTimeout(() => {
        setEnableFormAlert(false);
        setformAlertDetails({});
      }, 3000);
      dispatch(clearEditProviderProfileResponse());
    }
  }, [editProviderProfileStatus]);

  useEffect(() => {
    //console.log('editProviderProfessionalStatus success');
    if (editProviderProfessionalStatus === 'SUCCESS') {
      setformAlertDetails({ color: 'success', message: 'Successfully updated !' });
      setEnableFormAlert(true);
      setFetchingLoading(true);
      fetchProviderById(null, 'professional');
      setTimeout(() => {
        setEnableFormAlert(false);
        setformAlertDetails({});
      }, 3000);
      dispatch(clearEditProviderProfessionalResponse());
    } else if (editProviderProfessionalStatus === 'FAILED') {
      setformAlertDetails({ color: 'failure', message: 'Something went wrong !' });
      setEnableFormAlert(true);
      setTimeout(() => {
        setEnableFormAlert(false);
        setformAlertDetails({});
      }, 3000);
      dispatch(clearEditProviderProfessionalResponse());
    }
  }, [editProviderProfessionalStatus]);

  const getDropdwonData = () => {
    dispatch(getSystemProvidersRequest());
  };

  const onPageChange = (page: any) => {
    // console.log('=== changed page ===', page);
    setCurrentPage(page - 1);
    fetchProviders(PAGE_SIZE, page - 1);
  };

  // Clear states
  const onClearState = () => {
    setVisibleModal(false);
    // setVisibleEditForm(false);
    setVisibleAlert(false);
    // setMode('CREATE');
    // setSelectedProviderId(null);
    setSelectedToEdit({});
    setCreateFormData({});
    setCreateProfessionalFormData({});
    setErrorObj({});
    setAlertObj({ color: '', message: '', error: false });
    // setSelectedSection('');
    setEnableFormAlert(false);
    setFetchingLoading(false);
    setformAlertDetails({});
  };

  const fetchProviders = async (pageSize = PAGE_SIZE, pageNumber = currentPage, searchValue = searchTerm) => {
    dispatch(getProvidersRequest({ size: pageSize, page: pageNumber, search: encodeURIComponent(searchValue) }));
  };

  // Fetch provider id API call
  const fetchProviderById = async (id = null, section = '') => {
    dispatch(
      getProviderByIdRequest({
        userId: id ?? selectedProviderId,
        section: section ?? selectedSection,
      })
    );
  };

  //
  const handleModalVisible = () => {
    setMode('CREATE');
    setCreateFormData({});
    setVisibleModal(true);
  };

  const handleOnClose = () => {
    setMode('CREATE');
    setStepperId(1);
    setCreateFormData({});
    setCreateProfessionalFormData({});
    setErrorObj({});
    setformAlertDetails({});
    setSelectedProviderId(null);
    setEnableFormAlert(false);
    setVisibleModal(false);
    setVisibleEditForm(false);
    setFetchingLoading(false);
    fetchProviders();
  };

  const onEdit = (event: any, rowIndex: any) => {
    event.preventDefault();
    setSelectedChild(1);
    const dataSet = dataSource[rowIndex];
    setSelectedProviderId(dataSet?.id);
    // console.log('==== rowIndex - edit ====', rowIndex, dataSet);
    setMode('EDIT');
    setSelectedToEdit(dataSet);
    setSelectedSection('profile');
    fetchProviderById(dataSet?.id, 'profile');
  };

  const onEditQueryParams = (id: any) => {
    setSelectedChild(1);
    setMode('EDIT');
    setSelectedProviderId(id);
    setSelectedSection('profile');
    fetchProviderById(id, 'profile');
  };

  const onView = (event: any, rowIndex: any) => {
    event.preventDefault();
    setSelectedChild(1);
    setMode('VIEW');
    const dataSet = dataSource[rowIndex];
    setSelectedProviderId(dataSet?.id);
    setSelectedToEdit(dataSet);
    setSelectedSection('profile');
    fetchProviderById(dataSet?.id, 'profile');
  };

  const errorAlertOnClose = () => {
    setEnableFormAlert(false);
    setformAlertDetails({});
  };

  const onChangeProviderCreateForm = (e: any) => {
    if (errorObj?.hasOwnProperty(e.target.name)) {
      delete errorObj[e.target.name];
    }
    setCreateFormData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const onChangeProviderProfessionalCreateForm = (e: any) => {
    const { name, type, value, checked } = e.target;

    const convertedValue = type === 'checkbox' ? checked : value === 'true' ? true : value === 'false' ? false : value;

    if (errorObj?.hasOwnProperty(name)) {
      delete errorObj[name];
    }
    setCreateProfessionalFormData((prev: any) => ({
      ...prev,
      [name]: convertedValue,
    }));
  };

  const onChangeProviderProfessionalDateChange = (name: string, date: Date) => {
    if (errorObj?.hasOwnProperty(name)) {
      delete errorObj[name];
    }
    setCreateProfessionalFormData((prev: any) => ({
      ...prev,
      [name]: moment(date).format('YYYY-MM-DD'),
    }));
  };

  const onSelectDOB = (date: Date) => {
    if (errorObj?.hasOwnProperty('dob')) {
      delete errorObj['dob'];
    }
    setCreateFormData((prev: any) => ({
      ...prev,
      dob: moment(date).format('YYYY-MM-DD'),
    }));
  };

  const onSelectTerminationDate = (date: Date) => {
    if (errorObj?.hasOwnProperty('terminationDate')) {
      delete errorObj['terminationDate'];
    }
    setCreateFormData((prev: any) => ({
      ...prev,
      terminationDate: moment(date).format('YYYY-MM-DD'),
    }));
  };

  const onSelectStartDate = (date: Date) => {
    if (errorObj?.hasOwnProperty('startDate')) {
      delete errorObj['startDate'];
    }
    setCreateFormData((prev: any) => ({
      ...prev,
      startDate: moment(date).format('YYYY-MM-DD'),
    }));
  };

  const onSelectDrivingLicenseExDate = (date: Date) => {
    if (errorObj?.hasOwnProperty('dlExpirationDate')) {
      delete errorObj['dlExpirationDate'];
    }
    setCreateFormData((prev: any) => ({
      ...prev,
      dlExpirationDate: moment(date).format('YYYY-MM-DD'),
    }));
  };

  const convertBeforeSubmit = (formData: any) => {
    const obj = {
      degreeId: null,
      dlExpirationDate: formatDate(formData?.dlExpirationDate),
      dob: formatDate(formData?.dob),
      driverLicense: formData?.driverLicense,
      ein: formData?.ein,
      employmentType: formData?.employmentType,
      firstName: formData?.firstName || null,
      gender: formData?.gender,
      homePhone: formData?.homePhone,
      languagesSpoken: formData?.languagesSpoken,
      lastName: formData?.lastName || null,
      middleName: formData?.middleName,
      mobilePhone: formData?.mobilePhone,
      personalEmail: formData?.personalEmail,
      providerStatus: formData?.providerStatus,
      providerType: formData?.providerType,
      ssn: formData?.ssn,
      startDate: formatDate(formData?.startDate),
      state: formData?.state,
      taxonomy: formData?.taxonomy || null,
      terminationDate: formatDate(formData?.terminationDate),
      workEmail: formData?.workEmail,
    };

    // console.log('== degree check ===', obj);

    return obj;
  };

  const convertProfessionBeforeSubmit = (formData: any) => {
    const obj = {
      npi: formData?.npi || null,
      userId: parseInt(formData?.userId, 10),
      password: formData?.password,
      deaNo: formData?.deaNo || null,
      effectiveDate: formatDate(formData?.effectiveDate),
      expirationDate: formatDate(formData?.expirationDate),
      appliedFor: formData?.appliedFor,
      controlledSubstanceNo: formData?.controlledSubstanceNo,
      pliNo: formData?.pliNo,
      meNumber: formData?.meNumber,
      ptan: formData?.ptan,
      ptanEffectiveDate: formatDate(formData?.ptanEffectiveDate),
      everEnrolled: formData?.everEnrolled,
      cehrtAvailability: formData?.cehrtAvailability,
      pecos: formData?.pecos,
      pecosUserId: formData?.pecosUserId || null,
      pecosPassword: formData?.pecosPassword,
      understand: formData?.understand,
      staffUpdate: formData?.staffUpdate,
      caqh: formData?.caqh || null,
      caqhUserId: formData?.caqhUserId || null,
      caqhPassword: formData?.caqhPassword || null,
      lastReattestionDate: formatDate(formData?.lastReattestionDate),
      nextReattestionDate: formatDate(formData?.nextReattestionDate),
      caqhNote: formData?.caqhNote,
      caqhEffectiveDate: formatDate(formData?.caqhEffectiveDate),
      caqhExpirationDate: formatDate(formData?.caqhExpirationDate),
      ceffectiveDate: formatDate(formData?.ceffectiveDate),
      cexpirationDate: formatDate(formData?.cexpirationDate),
      pexpirationDate: formatDate(formData?.pexpirationDate),
      peffectiveDate: formatDate(formData?.peffectiveDate),
    };

    return obj;
  };

  const createErrorAlert = (validationDetails: any) => {
    const errorMessages = Object.values(validationDetails.newErrors);
    return {
      message: (
        <div>
          {errorMessages.map((msg: any, index) => (
            <div key={index} className="flex items-center">
              <IoIosInformationCircleOutline />
              <span style={{ marginLeft: '8px' }}>{msg}</span>
            </div>
          ))}
        </div>
      ),
      color: 'failure',
    };
  };

  const onSubmit = () => {
    const editObj = {
      providerId: selectedProviderId,
      profileData: convertBeforeSubmit(createFormData),
    };

    if (mode === 'CREATE') {
      const validationDetails = createProviderValidation(createFormData);

      if (Object.keys(validationDetails?.newErrors)?.length > 0) {
        setErrorObj(validationDetails?.newErrors);

        setEnableFormAlert(true);
        setformAlertDetails(createErrorAlert(validationDetails));
      } else {
        dispatch(addProviderRequest(convertBeforeSubmit(createFormData)));
      }
    } else {
      if (selectedSection === 'profile') {
        const validationDetails = createProviderValidation(editObj?.profileData);
        if (Object.keys(validationDetails?.newErrors)?.length > 0) {
          setErrorObj(validationDetails?.newErrors);

          setEnableFormAlert(true);
          setformAlertDetails(createErrorAlert(validationDetails));
        } else {
          dispatch(editProviderProfileRequest(editObj));
        }
      } else if (selectedSection === 'professional') {
        // console.log('prf selected');
        const editObjProfession = {
          providerId: selectedProviderId,
          professionData: convertProfessionBeforeSubmit(createProfessionalFormData),
        };

        // console.log('Professional Edit Obj', editObjProfession);

        const validationDetails = createProviderProfessionValidation(editObjProfession.professionData);
        if (Object.keys(validationDetails?.newErrors)?.length > 0) {
          setErrorObj(validationDetails?.newErrors);

          setEnableFormAlert(true);
          setformAlertDetails(createErrorAlert(validationDetails));
        } else {
          dispatch(editProviderProfessionalRequest(editObjProfession));
        }
      }
    }
  };

  // console.log('=== createFormData ===', createFormData, visibleAlert);
  // console.log('=== dataSource ===', dataSource, loading);

  const handleAlertClose = () => {
    setVisibleAlert(false);
  };

  const renderTab = () => {
    switch (selectedChild) {
      case 1:
        return fetchingLoading ? (
          <Spinner />
        ) : (
          <Profile
            onChange={onChangeProviderCreateForm}
            onSelectDOB={onSelectDOB}
            onSelectStartDate={onSelectStartDate}
            onSelectDrivingLicenseExDate={onSelectDrivingLicenseExDate}
            onSelectTerminationDate={onSelectTerminationDate}
            profileData={createFormData}
            errorObject={errorObj}
            mode={mode}
          />
        );
      case 2:
        return (
          <ProviderAddress
            selectedId={selectedProviderId}
            fetchProviderById={fetchProviderById}
            mode={mode}
            fetchDataLoading={fetchProviderByIdLoading}
            addDataLoading={addProviderLoading}
          />
        );
      case 3:
        return fetchingLoading ? (
          <Spinner />
        ) : (
          <ProviderProfession
            onChange={onChangeProviderProfessionalCreateForm}
            professionalData={createProfessionalFormData}
            errorObject={errorObj}
            onDateChange={onChangeProviderProfessionalDateChange}
            mode={mode}
          />
        );
      case 4:
        return <ProviderLicense selectedId={selectedProviderId} fetchProviderById={fetchProviderById} mode={mode} />;
      case 5:
        return (
          <ProviderCarriers
            selectedId={selectedProviderId}
            fetchProviderById={fetchProviderById}
            mode={mode}
            fetchDataLoading={fetchProviderByIdLoading}
          />
        );
      case 6:
        return (
          <ProviderFacilities
            selectedId={selectedProviderId}
            fetchProviderById={fetchProviderById}
            mode={mode}
            fetchDataLoading={fetchProviderByIdLoading}
          />
        );
      case 7:
        return <ProviderDocuments mode={mode} />;
      default:
        break;
    }
  };

  const handleClickStepper = (id: any) => {
    setSelectedChild(id);
    setSelectedSection(stepperData[id - 1]?.requestParam.toLowerCase());
    setStepperId(stepperData[id - 1]?.id);
    setFetchingLoading(true);
    fetchProviderById(null, stepperData[id - 1]?.requestParam.toLowerCase());
  };

  //console.log('Professional Form', createProfessionalFormData);

  return (
    <div className="main-content">
      <MainHeader />
      {visibleAlert && !alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}
      <PageTopic onClick={handleModalVisible} mainTitle="Providers" breadCrumbArr={breadCrumbArr} />
      {/* <TableAdvanceSearch /> */}

      <div className="flex justify-end mb-4">
        <div className="flex items-center">
          <label htmlFor="search" className="mr-2 text-gray-600">
            Search:
          </label>
          <input
            type="text"
            className="border border-gray-300 rounded px-2 py-1 focus:outline-0 focus:ring-primaryDefault focus:border-primaryDefault"
            name="search"
            title="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      {loading ? (
        <Spinner />
      ) : (
        <>
          {dataSource?.length > 0 ? (
            <TableContent
              enableActions={true}
              columns={columns}
              dataSource={dataSource}
              enableView={true}
              enableEdit={true}
              onEdit={onEdit}
              onView={onView}
            />
          ) : (
            <EmptyContent onClick={handleModalVisible} />
          )}

          {dataSource?.length > 0 && (
            <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
          )}
        </>
      )}

      {visibleModal ? (
        <RightSheetLarge
          onClose={handleOnClose}
          title="Create Provider"
          submitButtonTitle="Create"
          cancelButtonTitle="Cancel"
          enableAlert={enableFormAlert}
          alertDetails={formAlertDetails}
          alertOnClose={errorAlertOnClose}
          enableStepper={false}
          children={
            <Profile
              onChange={onChangeProviderCreateForm}
              onSelectDOB={onSelectDOB}
              profileData={createFormData}
              onSelectStartDate={onSelectStartDate}
              onSelectDrivingLicenseExDate={onSelectDrivingLicenseExDate}
              onSelectTerminationDate={onSelectTerminationDate}
              errorObject={errorObj}
            />
          }
          onSubmit={onSubmit}
          loading={addProviderLoading}
        />
      ) : (
        <></>
      )}

      {visibleEditForm ? (
        <RightSheetLarge
          title={mode === 'VIEW' ? 'View Provider' : 'Update Provider'}
          onSubmit={onSubmit}
          onClose={handleOnClose}
          submitButtonTitle="Update"
          cancelButtonTitle="Cancel"
          clickStepper={handleClickStepper}
          children={<>{renderTab()}</>}
          enableFooterButtons={mode === 'VIEW' ? false : stepperId === 1 || stepperId === 3}
          stepperData={stepperData}
          enableAlert={enableFormAlert}
          alertDetails={formAlertDetails}
          alertOnClose={errorAlertOnClose}
          selectedTab={stepperId}
        />
      ) : (
        <></>
      )}
      {/* <RightSheetLarge /> */}
    </div>
  );
};

import { useState } from 'react';
import { formatDatePickerDate } from '../../utils/commonFunctions';
import { DatePickerField } from '../atoms/DatePickerField';
import moment from 'moment';
import { SelectInput } from '../atoms/SelectInput';
import { useAppSelector } from '../../hooks/storeHooks/hooks';
import { getSystemProvidersSelector } from '../../redux/slices/system/getSystemProvidersSlice';
import { usStates } from '../../constants/systemConstants';
import { PrimaryButton } from '../atoms/PrimaryButton';
import { CommonButton } from '../atoms/CommonButton';
import { FaFileExcel } from 'react-icons/fa';

export const RVUReportAdvanceSearch = ({ onSubmit }: any) => {
  const [filterObject, setFilterObject] = useState<any>({});
  const { systemProvidersProviderData } = useAppSelector(getSystemProvidersSelector);

  const initialFromDate = moment().startOf('month').format('YYYY-MM-DD');
  const initialToDate = moment().endOf('month').format('YYYY-MM-DD');

  const onChangeFilter = (e: any) => {
    setFilterObject((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onChangeDate = (name: string, date: any) => {
    setFilterObject((prev: any) => ({ ...prev, [name]: moment(date).format('YYYY-MM-DD') }));
  };

  return (
    <div className="table-content__adv-search">
      <div className="table-content__adv-search-content">
        <div className="grid md:grid-cols-5 gap-4">
          <DatePickerField
            label="Visit Date From"
            selectedDate={formatDatePickerDate(filterObject?.visitDateFrom)}
            onChange={(date: Date | null) => {
              if (date) {
                onChangeDate('visitDateFrom', date);
              }
            }}
          />
          <DatePickerField
            label="Visit Date To"
            selectedDate={formatDatePickerDate(filterObject?.visitDateTo)}
            onChange={(date: Date | null) => {
              if (date) {
                onChangeDate('visitDateTo', date);
              }
            }}
          />
          <DatePickerField
            label="Visit Create Date From"
            selectedDate={formatDatePickerDate(filterObject?.visitDateFrom)}
            onChange={(date: Date | null) => {
              if (date) {
                onChangeDate('visitDateFrom', date);
              }
            }}
          />
          <DatePickerField
            label="Visit Create Date To"
            selectedDate={formatDatePickerDate(filterObject?.visitDateTo)}
            onChange={(date: Date | null) => {
              if (date) {
                onChangeDate('visitDateTo', date);
              }
            }}
          />
        </div>
        <div className="grid md:grid-cols-5 gap-4">
          <SelectInput
            defaultPlaceholder="Select Provider / PA"
            name="providerId"
            options={systemProvidersProviderData}
            onChange={onChangeFilter}
            value={filterObject?.providerId ?? ''}
          />
          <SelectInput
            defaultPlaceholder="All States"
            name="state"
            options={usStates}
            onChange={onChangeFilter}
            value={filterObject?.state ?? ''}
          />
          <div className="flex gap-2 justify-end">
            <PrimaryButton
              type="button"
              label="Submit"
              style={{ height: '40px', maxWidth: '100px' }}
              onClick={() => onSubmit(filterObject)}
            />
            <CommonButton
              label="Reset"
              buttonType="secondary"
              style={{ height: '40px', maxWidth: '100px' }}
              onClick={() => {
                setFilterObject({ dateFrom: initialFromDate, dateTo: initialToDate });
                onSubmit({ dateFrom: initialFromDate, dateTo: initialToDate });
              }}
            />
            <PrimaryButton
              type="button"
              label="Export to Excel"
              icon={<FaFileExcel style={{ fontSize: '18px' }} />}
              style={{ height: '40px', maxWidth: '100px' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

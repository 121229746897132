import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { FetchRVUReportInitialInterface, FetchRVUReportInterface } from '../../../interfaces/reportsInterfaces';

const initialState: FetchRVUReportInitialInterface = {
  fetchRVUReportLoading: false,
  fetchRVUReportData: {
    currentPage: 0,
    items: [],
    totalItems: 0,
    totalPages: 0,
  },
  fetchRVUReportError: null,
  fetchRVUReportStatus: 'IDLE',
};

export const getRVUReportRequest: any = createAsyncThunk(
  'reports/RVUReport/get',
  async (reportData: FetchRVUReportInterface, thunkAPI: any) => {
    try {
      //   const response: any = await getRVUReportAPIRequest(reportData);
      //   return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getRVUReportSlice = createSlice({
  name: 'fetchRVUReport',
  initialState,
  reducers: {
    clearRVUReportResponse: (state) => {
      state.fetchRVUReportLoading = false;
      state.fetchRVUReportData = { currentPage: 0, items: [], totalItems: 0, totalPages: 0 };
      state.fetchRVUReportStatus = 'IDLE';
      state.fetchRVUReportError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRVUReportRequest.pending, (state, action) => {
        state.fetchRVUReportLoading = true;
        state.fetchRVUReportStatus = 'PENDING';
      })
      .addCase(getRVUReportRequest.fulfilled, (state, action) => {
        state.fetchRVUReportLoading = false;
        state.fetchRVUReportStatus = 'SUCCESS';
        state.fetchRVUReportData = action.payload;
      })
      .addCase(getRVUReportRequest.rejected, (state, action) => {
        state.fetchRVUReportLoading = false;
        state.fetchRVUReportStatus = 'FAILED';
        state.fetchRVUReportError = action.payload;
      });
  },
});

export const { clearRVUReportResponse } = getRVUReportSlice.actions;

export const getRVUReportSelector = (state: RootState) => state.GetRVUReport;

export default getRVUReportSlice.reducer;

import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks/hooks';
import moment from 'moment';

import { MainHeader } from '../../components/mainHeader/MainHeader';
import { PageTopic } from '../../components/pageTopic/PageTopic';
import { TableContent } from '../../components/table/TableContent';
import { EmptyContent } from '../../components/emptyContent/EmptyContent';
import { CommonAlert } from '../../components/atoms/Alert';
import { Pagination } from '../../components/pagination/Pagination';
import { Spinner } from '../../components/atoms/Spinner';
import { VisitReportAdvanceSearch } from '../../components/tableSearch/VisitReportAdvanceSearch';
import { getSystemProvidersRequest } from '../../redux/slices/system/getSystemProvidersSlice';
import { getSystemFacilityRequest } from '../../redux/slices/system/getSystemFacilitiesSlice';
import {
  getSystemVisitStatusRequest,
  getSystemVisitStatusSelector,
} from '../../redux/slices/system/getSystemVisitStatusSlice';

import { formatCurrency } from '../../utils/commonFunctions';
import {
  clearRVUReportResponse,
  getRVUReportRequest,
  getRVUReportSelector,
} from '../../redux/slices/reports/getRVUReportSlice';
import { RVUReportAdvanceSearch } from '../../components/tableSearch/RVUReportAdvanceSearch';

const PAGE_SIZE = 10;

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Provider Name',
    dataIndex: 'providerName',
    key: 'providerName',
  },
  {
    title: 'Total Units',
    dataIndex: 'totalUnits',
    key: 'totalUnits',
  },
  {
    title: 'Variance',
    dataIndex: 'variance',
    key: 'variance',
  },
  {
    title: 'Amount Billed',
    dataIndex: 'amountBilled',
    key: 'amountBilled',
  },
  {
    title: 'Bonus Amount',
    dataIndex: 'bonusAmount',
    key: 'bonusAmount',
  },
];

const dummyDataSource = [
  {
    visitId: 241,
    visitDate: '2024-10-10',
    patientName: 'Williams Jane',
    provider: 'Medicare Co',
    facility: 'Healthcare Inc',
    state: 'JK',
    icds: '12000,23232',
    cpts: '99439,99490',
    charge: 1202.32,
    expected: 1322,
    createdAt: '2023-10-21T03:00:00Z',
  },
  {
    visitId: 213,
    visitDate: '2024-10-11',
    patientName: 'Brian James',
    provider: 'HealthFirst',
    facility: 'CareCo',
    state: 'KI',
    icds: '901,327',
    cpts: '8972,1223',
    charge: 891,
    expected: 1200,
    createdAt: '2023-11-09T05:05:00Z',
  },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  { id: 'payroll', label: 'Payroll', status: 'inactive', link: 'payroll' },
  {
    id: 'RVUReport',
    label: 'RVU Report',
    status: 'active',
    link: 'rvu-report',
  },
];

export const RVUReportPage = () => {
  const dispatch = useAppDispatch();

  const { fetchRVUReportStatus, fetchRVUReportData, fetchRVUReportLoading } = useAppSelector(getRVUReportSelector);
  const { systemVisitData } = useAppSelector(getSystemVisitStatusSelector);

  const initialFromDate = moment().startOf('month').format('YYYY-MM-DD');
  const initialToDate = moment().endOf('month').format('YYYY-MM-DD');

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [filterObject, setFilterObject] = useState<any>({ dateFrom: initialFromDate, dateTo: initialToDate });

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>({
    color: '',
    message: '',
    error: false,
  });

  useEffect(() => {
    if (systemVisitData?.length === 0) {
      getVisitStatus();
    }

    fetchSystemProviders();
    fetchSystemFacilities();
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  }, []);

  useEffect(() => {
    if (filterObject) {
      fetchRVUReport();
    }
  }, [filterObject]);

  useEffect(() => {
    if (fetchRVUReportStatus === 'SUCCESS') {
      setDataSource(fetchRVUReportData?.items);
      setCurrentPage(fetchRVUReportData?.currentPage);
      setTotalPages(fetchRVUReportData?.totalPages);
      dispatch(clearRVUReportResponse());
    } else if (fetchRVUReportStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj({
          color: '',
          message: '',
          error: false,
        });
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearRVUReportResponse());
    }
  }, [fetchRVUReportStatus]);

  const onPageChange = (page: any) => {
    setCurrentPage(page - 1);
    fetchRVUReport(page - 1);
  };

  const fetchRVUReport = async (pageNumber = currentPage) => {
    dispatch(getRVUReportRequest({ size: PAGE_SIZE, page: pageNumber, filters: filterObject }));
  };

  const getVisitStatus = () => {
    dispatch(getSystemVisitStatusRequest());
  };

  const fetchSystemProviders = () => {
    dispatch(getSystemProvidersRequest());
  };

  const fetchSystemFacilities = () => {
    dispatch(getSystemFacilityRequest());
  };

  const handleSearch = (data: any) => {
    const updatedData = { ...data };

    if (!updatedData?.providerId || updatedData?.providerId === '') {
      delete updatedData.providerId;
    } else {
      updatedData.providerId = parseInt(updatedData?.providerId, 10);
    }

    if (!updatedData?.facilityId || updatedData?.facilityId === '') {
      delete updatedData.facilityId;
    } else {
      updatedData.facilityId = parseInt(updatedData?.facilityId, 10);
    }

    if (!updatedData?.visitStatusId || updatedData?.visitStatusId === '') {
      delete updatedData.visitStatusId;
    } else {
      updatedData.visitStatusId = parseInt(updatedData?.visitStatusId, 10);
    }

    if (!updatedData?.state || updatedData?.state === '') {
      delete updatedData.state;
    }

    setCurrentPage(0);
    setFilterObject(updatedData);
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic mainTitle="RVU Report by Provider" enablePrimaryButton={false} breadCrumbArr={breadCrumbArr} />

      <RVUReportAdvanceSearch onSubmit={handleSearch} />

      {visibleAlert && alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}

      {fetchRVUReportLoading ? (
        <Spinner />
      ) : (
        <>
          {dummyDataSource?.length > 0 ? (
            <>
              <div className="flex justify-end mb-4">
                <div className="flex items-center">
                  <label htmlFor="search" className="mr-2 text-gray-600">
                    Search:
                  </label>
                  <input
                    type="text"
                    className="border border-gray-300 rounded px-2 py-1"
                    name="search"
                    title="Search"
                    disabled
                  />
                </div>
              </div>

              <TableContent columns={columns} dataSource={dummyDataSource} enableActions={false} />
              {totalPages > 1 && (
                <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
              )}
            </>
          ) : (
            <EmptyContent mode="VIEW" enableCreateButton={false} />
          )}
        </>
      )}
    </div>
  );
};

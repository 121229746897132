import { PrimaryButtonPropsInterface } from '../../interfaces/atomInterfaces';

export const PrimaryButton: React.FC<PrimaryButtonPropsInterface> = ({
  type = 'submit',
  label = 'Search',
  disabled = false,
  onClick = () => {},
  style,
  icon,
}) => {
  return (
    <>
      <button
        type={type}
        className={`
      px-4 
      py-2 
      text-white 
      bg-primaryDefault 
      border-primaryDefault 
      hover:bg-primaryHover 
      hover:text-white 
      focus:text-white 
      focus:hover:text-white 
      focus:z-10 
      focus:ring-2 
      focus:ring-primaryFocus 
      rounded-lg 
      font-medium 
      flex 
      items-center 
      w-full 
      justify-center ${disabled ? 'cursor-not-allowed opacity-50' : ''}`}
        onClick={onClick}
        disabled={disabled}
        style={style}
      >
        {icon ? icon : label}
      </button>
    </>
  );
};

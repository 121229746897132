import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  FetchPayrollRBProviderInitialInterface,
  FetchPayrollRBProviderInterface,
} from '../../../interfaces/reportsInterfaces';

const initialState: FetchPayrollRBProviderInitialInterface = {
  fetchPayrollRBProviderLoading: false,
  fetchPayrollRBProviderData: {
    currentPage: 0,
    items: [],
    totalItems: 0,
    totalPages: 0,
  },
  fetchPayrollRBProviderError: null,
  fetchPayrollRBProviderStatus: 'IDLE',
};

export const getPayrollRBProviderRequest: any = createAsyncThunk(
  'reports/payrollRBP/get',
  async (reportData: FetchPayrollRBProviderInterface, thunkAPI: any) => {
    try {
      //   const response: any = await getPayrollRBProviderAPIRequest(reportData);
      //   return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getPayrollRBProviderSlice = createSlice({
  name: 'fetchPayrollRBProvider',
  initialState,
  reducers: {
    clearPayrollRBProviderResponse: (state) => {
      state.fetchPayrollRBProviderLoading = false;
      state.fetchPayrollRBProviderData = { currentPage: 0, items: [], totalItems: 0, totalPages: 0 };
      state.fetchPayrollRBProviderStatus = 'IDLE';
      state.fetchPayrollRBProviderError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPayrollRBProviderRequest.pending, (state, action) => {
        state.fetchPayrollRBProviderLoading = true;
        state.fetchPayrollRBProviderStatus = 'PENDING';
      })
      .addCase(getPayrollRBProviderRequest.fulfilled, (state, action) => {
        state.fetchPayrollRBProviderLoading = false;
        state.fetchPayrollRBProviderStatus = 'SUCCESS';
        state.fetchPayrollRBProviderData = action.payload;
      })
      .addCase(getPayrollRBProviderRequest.rejected, (state, action) => {
        state.fetchPayrollRBProviderLoading = false;
        state.fetchPayrollRBProviderStatus = 'FAILED';
        state.fetchPayrollRBProviderError = action.payload;
      });
  },
});

export const { clearPayrollRBProviderResponse } = getPayrollRBProviderSlice.actions;

export const getPayrollRBProviderSelector = (state: RootState) => state.GetPayrollRBProvider;

export default getPayrollRBProviderSlice.reducer;

import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks/hooks';

import { Modal } from 'flowbite-react';
import { SelectInput } from '../../../atoms/SelectInput';
import { TextArea } from '../../../atoms/TextArea';
import { CommonButton } from '../../../atoms/CommonButton';
import { CommonAlert } from '../../../atoms/Alert';

import { IoIosInformationCircleOutline } from 'react-icons/io';

import {
  updateVisitEligibilityStatusRequest,
  updateVisitEligibilityStatusSelector,
  clearUpdateVisitEligibilityStatusResponse,
} from '../../../../redux/slices/patient/editVisitEligibilityStatusSlice';

import { editVisitEligibilityStatusOptions } from '../../../../constants/patientConstants';
import { editVisitEligibilityStatusValidation } from '../../../../utils/patientValidation';

const initialAlertState = {
  color: '',
  message: '',
  error: false,
};

export const EditEligibilityStatusModal = ({
  visibleEditEligibilityStatusModal,
  onCloseModal,
  patientId,
  visitId,
  visitEligibleToClaimCreate,
  fetchPatientById,
  fetchVisits,
}: any) => {
  const dispatch = useAppDispatch();

  const {
    editVisitEligibilityStatusStatus,
    editVisitEligibilityStatusData,
    editVisitEligibilityStatusLoading,
    editVisitEligibilityStatusError,
  } = useAppSelector(updateVisitEligibilityStatusSelector);

  const [formData, setFormData] = useState<any>({});

  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>(initialAlertState);

  useEffect(() => {
    setFormData((prev: any) => ({
      ...prev,
      isActive: visitEligibleToClaimCreate ? 'ACTIVE' : 'INACTIVE',
    }));
  }, [visitEligibleToClaimCreate]);

  useEffect(() => {
    if (editVisitEligibilityStatusStatus === 'SUCCESS') {
      if (editVisitEligibilityStatusData?.isSuccess === true) {
        setAlertObj({
          color: 'success',
          message: 'Updated Successfully!',
          error: true,
        });
        setVisibleAlert(true);
        setTimeout(() => {
          onCloseModal();
          fetchVisits && fetchVisits();
          fetchPatientById && fetchPatientById(patientId, 'visit');
        }, 2000);
      } else {
        setAlertObj({
          color: 'failure',
          message: editVisitEligibilityStatusData?.message ?? 'Something went wrong!',
          error: true,
        });
        setVisibleAlert(true);
      }

      setTimeout(() => {
        setAlertObj(initialAlertState);
        setVisibleAlert(false);
      }, 3000);

      dispatch(clearUpdateVisitEligibilityStatusResponse());
    } else if (editVisitEligibilityStatusStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: editVisitEligibilityStatusError?.message ?? 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
        setAlertObj(initialAlertState);
      }, 3000);

      dispatch(clearUpdateVisitEligibilityStatusResponse());
    }
  }, [editVisitEligibilityStatusStatus]);

  const onSubmit = () => {
    const validationDetails = editVisitEligibilityStatusValidation(formData);

    if (Object.keys(validationDetails?.newErrors)?.length > 0) {
      const errorMessages = Object.values(validationDetails.newErrors);
      setAlertObj({
        message: (
          <div>
            {errorMessages.map((msg: any, index) => (
              <div key={index} className="flex items-center">
                <IoIosInformationCircleOutline />
                <span className="ml-2">{msg}</span>
              </div>
            ))}
          </div>
        ),
        color: 'failure',
        error: true,
      });
      setVisibleAlert(true);
    } else {
      const obj = {
        patientId,
        visitId,
        data: {
          isActive: formData.isActive === 'ACTIVE',
          note: formData.note,
        },
      };

      dispatch(updateVisitEligibilityStatusRequest(obj));
    }
  };

  const handleFieldValueChange = (e: any) => {
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
  };

  return (
    <Modal show={visibleEditEligibilityStatusModal} onClose={onCloseModal} size="3xl">
      <Modal.Header>Edit Eligibility Status</Modal.Header>
      <Modal.Body>
        {visibleAlert && alertObj?.error && (
          <CommonAlert
            color={alertObj?.color}
            message={alertObj?.message}
            onClose={handleAlertClose}
            alertClassName="w-1/2"
          />
        )}
        <div className="grid md:grid-cols-3 gap-4 mt-6">
          <SelectInput
            label="Status"
            options={editVisitEligibilityStatusOptions}
            enableDefaultPlaceholder={true}
            name="isActive"
            onChange={handleFieldValueChange}
            value={formData?.isActive ?? undefined}
            required
          />
          <div className="col-span-2">
            <TextArea
              label="Note"
              name="note"
              onChange={handleFieldValueChange}
              value={formData?.note ?? ''}
              required
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="grid md:grid-cols-3">
        <div className="col-span-2"></div>
        <div className="col-span-1 grid md:grid-cols-2 gap-2">
          <CommonButton type="button" label={'Close'} buttonType="secondary" onClick={onCloseModal} />
          <CommonButton
            type="button"
            label={'Submit'}
            buttonType="primary"
            onClick={onSubmit}
            loading={editVisitEligibilityStatusLoading}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { FetchArAnalysisInterface, FetchArAnalysisInitialInterface } from '../../../interfaces/reportsInterfaces';
import { getArAnalysisAPIRequest } from '../../../api/reports/accountRecievable';

const initialState: FetchArAnalysisInitialInterface = {
  fetchArAnalysisLoading: false,
  fetchArAnalysisData: {
    currentPage: 0,
    items: [],
    totalItems: 0,
    totalPages: 0,
  },
  fetchArAnalysisError: null,
  fetchArAnalysisStatus: 'IDLE',
};

export const getArAnalysisRequest: any = createAsyncThunk(
  'reports/ar-analysis/get',
  async (reportData: FetchArAnalysisInterface, thunkAPI: any) => {
    try {
      const response: any = await getArAnalysisAPIRequest(reportData);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getArAnalysisSlice = createSlice({
  name: 'fetchArAnalysis',
  initialState,
  reducers: {
    clearFetchArAnalysisResponse: (state) => {
      state.fetchArAnalysisLoading = false;
      state.fetchArAnalysisData = { currentPage: 0, items: [], totalItems: 0, totalPages: 0 };
      state.fetchArAnalysisStatus = 'IDLE';
      state.fetchArAnalysisError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getArAnalysisRequest.pending, (state, action) => {
        state.fetchArAnalysisLoading = true;
        state.fetchArAnalysisStatus = 'PENDING';
      })
      .addCase(getArAnalysisRequest.fulfilled, (state, action) => {
        state.fetchArAnalysisLoading = false;
        state.fetchArAnalysisStatus = 'SUCCESS';
        state.fetchArAnalysisData = action.payload;
      })
      .addCase(getArAnalysisRequest.rejected, (state, action) => {
        state.fetchArAnalysisLoading = false;
        state.fetchArAnalysisStatus = 'FAILED';
        state.fetchArAnalysisError = action.payload;
      });
  },
});

export const { clearFetchArAnalysisResponse } = getArAnalysisSlice.actions;

export const getArAnalysisSelector = (state: RootState) => state.GetArAnalysis;

export default getArAnalysisSlice.reducer;

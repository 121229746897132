import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { FetchDBRSnapshotInterface, FetchDBRSnapshotInitialInterface } from '../../../interfaces/reportsInterfaces';

const initialState: FetchDBRSnapshotInitialInterface = {
  fetchDBRSnapshotLoading: false,
  fetchDBRSnapshotData: {
    currentPage: 0,
    items: [],
    totalItems: 0,
    totalPages: 0,
  },
  fetchDBRSnapshotError: null,
  fetchDBRSnapshotStatus: 'IDLE',
};

export const getDBRSnapshotRequest: any = createAsyncThunk(
  'reports/dashboard-bank/get',
  async (reportData: FetchDBRSnapshotInterface, thunkAPI: any) => {
    try {
      //   const response: any = await getDBRSnapshotAPIRequest(reportData);
      //   return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getDBRSnapshotSlice = createSlice({
  name: 'fetchDBRSnapshot',
  initialState,
  reducers: {
    clearFetchDBRSnapshotResponse: (state) => {
      state.fetchDBRSnapshotLoading = false;
      state.fetchDBRSnapshotData = { currentPage: 0, items: [], totalItems: 0, totalPages: 0 };
      state.fetchDBRSnapshotStatus = 'IDLE';
      state.fetchDBRSnapshotError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDBRSnapshotRequest.pending, (state, action) => {
        state.fetchDBRSnapshotLoading = true;
        state.fetchDBRSnapshotStatus = 'PENDING';
      })
      .addCase(getDBRSnapshotRequest.fulfilled, (state, action) => {
        state.fetchDBRSnapshotLoading = false;
        state.fetchDBRSnapshotStatus = 'SUCCESS';
        state.fetchDBRSnapshotData = action.payload;
      })
      .addCase(getDBRSnapshotRequest.rejected, (state, action) => {
        state.fetchDBRSnapshotLoading = false;
        state.fetchDBRSnapshotStatus = 'FAILED';
        state.fetchDBRSnapshotError = action.payload;
      });
  },
});

export const { clearFetchDBRSnapshotResponse } = getDBRSnapshotSlice.actions;

export const getDBRSnapshotSelector = (state: RootState) => state.GetDBRSnapshot;

export default getDBRSnapshotSlice.reducer;

import React from 'react';
import { FinancialDashboardByPayerMixReportTableProps } from '../../interfaces/commonInterfaces';

export const FinancialDashboardByPayerMixCustomTable: React.FC<FinancialDashboardByPayerMixReportTableProps> = ({
  columns = [],
  dataSource = {
    startingBalanceAR: {},
    monthlyData: [],
    totalData: {
      totals: {},
      payorMix: [],
    },
  },
}) => {
  return (
    <div className="table-content">
      <div className="table-content__table-view">
        <table className="table-content__table">
          <thead className="table-content__table-header">
            {columns?.map((column) => (
              <th key={column.key} className="text-center">
                {column.title}
              </th>
            ))}
          </thead>
          <tbody className="table-content__table-body">
            <tr className="font-semibold">
              <td>Starting Balance AR</td>
              {columns?.slice(1).map((column) => (
                <td key={column.key}>
                  {' '}
                  {column.render
                    ? column.render(dataSource?.startingBalanceAR?.[column.dataIndex], dataSource?.startingBalanceAR)
                    : dataSource?.startingBalanceAR?.[column.dataIndex]}
                </td>
              ))}
            </tr>
            {dataSource.monthlyData?.map((monthData, monthIndex) => (
              <React.Fragment key={monthIndex}>
                <tr className="font-semibold">
                  <td>{monthData?.month}</td>
                  <td>Total</td>
                  {columns.slice(2).map((column) => (
                    <td>
                      {column.render
                        ? column.render(monthData?.totals?.[column.dataIndex], monthData?.totals)
                        : monthData?.totals?.[column.dataIndex]}
                    </td>
                  ))}
                </tr>
                {monthData.payorMix?.map((payor, payorIndex) => (
                  <tr key={payorIndex}>
                    <td></td>
                    <td>{payor?.name}</td>
                    {columns.slice(2).map((column) => (
                      <td>{column.render ? column.render(payor[column.dataIndex], payor) : payor[column.dataIndex]}</td>
                    ))}
                  </tr>
                ))}
              </React.Fragment>
            ))}

            <tr className="font-semibold">
              <td>Total This Year</td>
              <td></td>
              {columns?.slice(2).map((column) => (
                <td key={column.key}>
                  {' '}
                  {column.render
                    ? column.render(dataSource?.totalData?.totals?.[column.dataIndex], dataSource?.totalData?.totals)
                    : dataSource?.totalData?.totals?.[column.dataIndex]}
                </td>
              ))}
            </tr>
            {dataSource.totalData?.payorMix?.map((payor, payorIndex) => (
              <tr key={payorIndex} className="font-semibold">
                <td></td>
                <td>{payor?.name}</td>
                {columns.slice(2).map((column) => (
                  <td>{column.render ? column.render(payor[column.dataIndex], payor) : payor[column.dataIndex]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

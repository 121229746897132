import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { FetchPrimaryAgingSummaryInitialInterface } from '../../../interfaces/reportsInterfaces';
import { getPrimaryAgingSummaryAPIRequest } from '../../../api/reports/accountRecievable';

const initialState: FetchPrimaryAgingSummaryInitialInterface = {
  fetchPrimaryAgingSummaryLoading: false,
  fetchPrimaryAgingSummaryData: {},
  fetchPrimaryAgingSummaryStatus: 'IDLE',
  fetchPrimaryAgingSummaryError: null,
};

export const getPrimaryAgingSummaryRequest: any = createAsyncThunk(
  'reports/primary-aging-summary/get',
  async (thunkAPI: any) => {
    try {
      const response: any = await getPrimaryAgingSummaryAPIRequest();
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getPrimaryAgingSummarySlice = createSlice({
  name: 'fetchPrimaryAgingSummary',
  initialState,
  reducers: {
    clearFetchPrimaryAgingSummaryResponse: (state) => {
      state.fetchPrimaryAgingSummaryLoading = false;
      state.fetchPrimaryAgingSummaryStatus = 'IDLE';
      state.fetchPrimaryAgingSummaryData = {};
      state.fetchPrimaryAgingSummaryError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPrimaryAgingSummaryRequest.pending, (state, action) => {
        state.fetchPrimaryAgingSummaryLoading = true;
        state.fetchPrimaryAgingSummaryStatus = 'PENDING';
      })
      .addCase(getPrimaryAgingSummaryRequest.fulfilled, (state, action) => {
        state.fetchPrimaryAgingSummaryLoading = false;
        state.fetchPrimaryAgingSummaryData = action.payload;
        state.fetchPrimaryAgingSummaryStatus = 'SUCCESS';
      })
      .addCase(getPrimaryAgingSummaryRequest.rejected, (state, action) => {
        state.fetchPrimaryAgingSummaryLoading = false;
        state.fetchPrimaryAgingSummaryError = action.payload;
        state.fetchPrimaryAgingSummaryStatus = 'FAILED';
      });
  },
});

export const { clearFetchPrimaryAgingSummaryResponse } = getPrimaryAgingSummarySlice.actions;

export const getPrimaryAgingSummarySelector = (state: RootState) => state.GetPrimaryAgingSumamry;

export default getPrimaryAgingSummarySlice.reducer;

import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks/hooks';
import moment from 'moment';

import { MainHeader } from '../../components/mainHeader/MainHeader';
import { PageTopic } from '../../components/pageTopic/PageTopic';
import { TableContent } from '../../components/table/TableContent';
import { EmptyContent } from '../../components/emptyContent/EmptyContent';
import { Pagination } from '../../components/pagination/Pagination';
import { CommonAlert } from '../../components/atoms/Alert';
import { Spinner } from '../../components/atoms/Spinner';
import { FinancialDashboardArReportAdvanceSearch } from '../../components/tableSearch/FinancialDashboardArReportAdvanceSearch';

import {
  getFinancialDRArRequest,
  getFinancialDRArSelector,
  clearFetchFinancialDRArResponse,
} from '../../redux/slices/reports/getFinancialDRArSlice';

import { formatCurrency } from '../../utils/commonFunctions';

const columns = [
  {
    title: 'Visit Date / Month',
    dataIndex: 'visitDate',
    key: 'visitDate',
    render: (text: string) => (moment(text).isValid() ? moment(text).format('MM/DD/YYYY') : ''),
  },
  {
    title: 'Charges',
    dataIndex: 'charges',
    key: 'charges',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Expected Amount',
    dataIndex: 'expectedAmount',
    key: 'expectedAmount',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Total Payment',
    dataIndex: 'totalPayment',
    key: 'totalPayment',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Insurance Payment',
    dataIndex: 'insurancePayment',
    key: 'insurancePayment',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Contractual Adjustments',
    dataIndex: 'contractualAdjustments',
    key: 'contractualAdjustments',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Non-Contractual Adjustments',
    dataIndex: 'nonContractualAdjustments',
    key: 'nonContractualAdjustments',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Patient Payments',
    dataIndex: 'patientPayments',
    key: 'patientPayments',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Insurance A/R',
    dataIndex: 'insuranceAR',
    key: 'insuranceAR',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Patient A/R',
    dataIndex: 'patientAR',
    key: 'patientAR',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'Unbilled A/R',
    dataIndex: 'unbilledAR',
    key: 'unbilledAR',
    render: (text: number) => formatCurrency(text ?? 0),
  },
  {
    title: 'GCR %',
    dataIndex: 'gcr',
    key: 'gcr',
  },
  {
    title: 'NCR %',
    dataIndex: 'ncr',
    key: 'ncr',
  },
];

const dummyDataSource = [
  {
    visitDate: '2024-01-15',
    charges: 1200.5,
    expectedAmount: 1150.75,
    totalPayment: 1100.0,
    insurancePayment: 800.25,
    contractualAdjustments: 50.25,
    nonContractualAdjustments: 25.5,
    patientPayments: 250.75,
    insuranceAR: 150.5,
    patientAR: 100.25,
    unbilledAR: 75.0,
    gcr: 85.5,
    ncr: 92.3,
  },
  {
    visitDate: '2024-02-10',
    charges: 1400.0,
    expectedAmount: 1350.3,
    totalPayment: 1300.25,
    insurancePayment: 900.0,
    contractualAdjustments: 70.0,
    nonContractualAdjustments: 30.25,
    patientPayments: 350.5,
    insuranceAR: 200.0,
    patientAR: 150.25,
    unbilledAR: 100.0,
    gcr: 88.4,
    ncr: 93,
  },
  {
    visitDate: '2024-03-05',
    charges: 1600.75,
    expectedAmount: 1550.5,
    totalPayment: 1500.25,
    insurancePayment: 950.75,
    contractualAdjustments: 100.5,
    nonContractualAdjustments: 50.0,
    patientPayments: 400.5,
    insuranceAR: 250.25,
    patientAR: 200.0,
    unbilledAR: 125.0,
    gcr: 90,
    ncr: 95,
  },
];

const breadCrumbArr = [
  { id: 'dashboard', label: 'Dashboard', status: 'inactive', link: 'dashboard' },
  {
    id: 'financialDashboardAR',
    label: 'Financial Dashboard Report AR',
    status: 'active',
    link: 'financial-dashboard-ar-report',
  },
];

const initialAlertState = {
  color: '',
  message: '',
  error: false,
};

export const FinancialDashboardArReportPage = () => {
  const dispatch = useAppDispatch();

  const { fetchFinancialDRArStatus, fetchFinancialDRArData, fetchFinancialDRArLoading } =
    useAppSelector(getFinancialDRArSelector);

  const initialFromDate = moment().startOf('month').format('YYYY-MM-DD');
  const initialToDate = moment().endOf('month').format('YYYY-MM-DD');

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [filterObject, setFilterObject] = useState<any>({
    dateFrom: initialFromDate,
    dateTo: initialToDate,
    filterBy: 'MONTH',
  });

  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [alertObj, setAlertObj] = useState<{ color: string; message: any; error: boolean }>(initialAlertState);

  useEffect(() => {
    setVisibleAlert(false);
    setAlertObj(initialAlertState);
  }, []);

  useEffect(() => {
    if (filterObject) {
      fetchFinancialDashboardArReport();
    }
  }, [filterObject]);

  useEffect(() => {
    if (fetchFinancialDRArStatus === 'SUCCESS') {
      setDataSource(fetchFinancialDRArData?.items);
      dispatch(clearFetchFinancialDRArResponse());
    } else if (fetchFinancialDRArStatus === 'FAILED') {
      setAlertObj({
        color: 'failure',
        message: 'Something went wrong!',
        error: true,
      });
      setVisibleAlert(true);
      setTimeout(() => {
        setAlertObj(initialAlertState);
        setVisibleAlert(false);
      }, 3000);
      dispatch(clearFetchFinancialDRArResponse());
    }
  }, [fetchFinancialDRArStatus]);

  const fetchFinancialDashboardArReport = () => {
    dispatch(getFinancialDRArRequest({ filters: filterObject }));
  };

  const handleSearch = (data: any) => {
    setFilterObject(data);
  };

  const handleAlertClose = () => {
    setVisibleAlert(false);
    setAlertObj({
      color: '',
      message: '',
      error: false,
    });
  };

  return (
    <div className="main-content">
      <MainHeader />
      <PageTopic mainTitle="Financial Dashboard Report AR" enablePrimaryButton={false} breadCrumbArr={breadCrumbArr} />

      <FinancialDashboardArReportAdvanceSearch onSubmit={handleSearch} />

      {visibleAlert && alertObj?.error && (
        <CommonAlert color={alertObj?.color} message={alertObj?.message} onClose={handleAlertClose} />
      )}

      {dummyDataSource && dummyDataSource?.length > 0 ? (
        <div className="overflow-x-auto">
          <TableContent columns={columns} dataSource={dummyDataSource} enableActions={false} />
        </div>
      ) : (
        <EmptyContent mode="VIEW" enableCreateButton={false} />
      )}
    </div>
  );
};

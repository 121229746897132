import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { FetchSecondaryAgingSummaryInitialInterface } from '../../../interfaces/reportsInterfaces';
import { getSecondaryAgingSummaryAPIRequest } from '../../../api/reports/accountRecievable';

const initialState: FetchSecondaryAgingSummaryInitialInterface = {
  fetchSecondaryAgingSummaryLoading: false,
  fetchSecondaryAgingSummaryData: {},
  fetchSecondaryAgingSummaryStatus: 'IDLE',
  fetchSecondaryAgingSummaryError: null,
};

export const getSecondaryAgingSummaryRequest: any = createAsyncThunk(
  'reports/secondary-aging-summary/get',
  async (thunkAPI: any) => {
    try {
      const response: any = await getSecondaryAgingSummaryAPIRequest();
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getSecondaryAgingSummarySlice = createSlice({
  name: 'fetchSecondaryAgingSummary',
  initialState,
  reducers: {
    clearFetchSecondaryAgingSummaryResponse: (state) => {
      state.fetchSecondaryAgingSummaryLoading = false;
      state.fetchSecondaryAgingSummaryStatus = 'IDLE';
      state.fetchSecondaryAgingSummaryData = {};
      state.fetchSecondaryAgingSummaryError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSecondaryAgingSummaryRequest.pending, (state, action) => {
        state.fetchSecondaryAgingSummaryLoading = true;
        state.fetchSecondaryAgingSummaryStatus = 'PENDING';
      })
      .addCase(getSecondaryAgingSummaryRequest.fulfilled, (state, action) => {
        state.fetchSecondaryAgingSummaryLoading = false;
        state.fetchSecondaryAgingSummaryData = action.payload;
        state.fetchSecondaryAgingSummaryStatus = 'SUCCESS';
      })
      .addCase(getSecondaryAgingSummaryRequest.rejected, (state, action) => {
        state.fetchSecondaryAgingSummaryLoading = false;
        state.fetchSecondaryAgingSummaryError = action.payload;
        state.fetchSecondaryAgingSummaryStatus = 'FAILED';
      });
  },
});

export const { clearFetchSecondaryAgingSummaryResponse } = getSecondaryAgingSummarySlice.actions;

export const getSecondaryAgingSummarySelector = (state: RootState) => state.GetSecondaryAgingSumamry;

export default getSecondaryAgingSummarySlice.reducer;

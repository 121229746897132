import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  FetchWeeklyAnalysisReportInterface,
  FetchWeeklyAnalysisReportInitialInterface,
} from '../../../interfaces/reportsInterfaces';
import { getWeeklyAnalysisReportAPIRequest } from '../../../api/reports/weeklyAnalysisReport';

const initialState: FetchWeeklyAnalysisReportInitialInterface = {
  fetchWeeklyAnalysisReportLoading: false,
  fetchWeeklyAnalysisReportData: {
    currentPage: 0,
    items: [],
    totalItems: 0,
    totalPages: 0,
  },
  fetchWeeklyAnalysisReportError: null,
  fetchWeeklyAnalysisReportStatus: 'IDLE',
};

export const getWeeklyAnalysisReportRequest: any = createAsyncThunk(
  'reports/weekly-analysis/get',
  async (searchParams: FetchWeeklyAnalysisReportInterface, thunkAPI: any) => {
    try {
      //   const response: any = await getWeeklyAnalysisReportAPIRequest(searchParams);
      //   return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }
      return thunkAPI.rejectWithValue(error?.response.data);
    }
  }
);

const getWeeklyAnalysisReportSlice = createSlice({
  name: 'fetchWeeklyAnalysisReport',
  initialState,
  reducers: {
    clearWeeklyAnalysisReportResponse: (state) => {
      state.fetchWeeklyAnalysisReportLoading = false;
      state.fetchWeeklyAnalysisReportData = { currentPage: 0, items: [], totalItems: 0, totalPages: 0 };
      state.fetchWeeklyAnalysisReportStatus = 'IDLE';
      state.fetchWeeklyAnalysisReportError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWeeklyAnalysisReportRequest.pending, (state, action) => {
        state.fetchWeeklyAnalysisReportLoading = true;
        state.fetchWeeklyAnalysisReportStatus = 'PENDING';
      })
      .addCase(getWeeklyAnalysisReportRequest.fulfilled, (state, action) => {
        state.fetchWeeklyAnalysisReportLoading = false;
        state.fetchWeeklyAnalysisReportStatus = 'SUCCESS';
        state.fetchWeeklyAnalysisReportData = action.payload;
      })
      .addCase(getWeeklyAnalysisReportRequest.rejected, (state, action) => {
        state.fetchWeeklyAnalysisReportLoading = false;
        state.fetchWeeklyAnalysisReportStatus = 'FAILED';
        state.fetchWeeklyAnalysisReportError = action.payload;
      });
  },
});

export const { clearWeeklyAnalysisReportResponse } = getWeeklyAnalysisReportSlice.actions;

export const getWeeklyAnalysisReportSelector = (state: RootState) => state.GetWeeklyAnalysisReport;

export default getWeeklyAnalysisReportSlice.reducer;

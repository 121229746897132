import { CheckboxPropsInterface } from '../../interfaces/atomInterfaces';

export const Checkbox: React.FC<CheckboxPropsInterface> = ({
  onChange,
  disabled,
  label,
  name,
  defaultChecked,
  checked,
  required,
}) => {
  return (
    <>
      <div className="flex items-center mr-4">
        <input
          id="default-checkbox"
          type="checkbox"
          value=""
          className={`
          w-4
          h-4
          text-primaryDefault
          bg-gray1 
          dark:bg-gray9
          border-gray3
          dark:border-gray3
          rounded
          focus:ring-primaryDefault
          dark:focus:ring-primaryFocus
          dark:ring-offset-gray8
          focus:ring-2 ${disabled ? 'opacity-80 cursor-not-allowed' : ''}`}
          name={name}
          onChange={onChange}
          disabled={disabled}
          defaultChecked={defaultChecked}
          checked={checked}
        />
        <label htmlFor="default-checkbox" className="ms-2 text-gray9 dark:text-white">
          {label} {required && <span className="text-red-500">*</span>}
        </label>
      </div>
    </>
  );
};

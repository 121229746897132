import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  AddPaymentAdjustmentCodeInterface,
  AddPaymentAdjustmentCodeInitialInterface,
} from '../../../interfaces/codesInterfaces';
import { addPaymentAdjustmentCodeAPIRequest } from '../../../api/account/paymentAdjustment';

const initialState: AddPaymentAdjustmentCodeInitialInterface = {
  addPaymentAdjustmentCodeLoading: false,
  addPaymentAdjustmentCodeData: {},
  addPaymentAdjustmentCodeError: null,
  addPaymentAdjustmentCodeStatus: 'IDLE',
};

export const addPaymentAdjustmentCodeRequest: any = createAsyncThunk(
  'payment-adjustment-code/add',
  async (codeData: AddPaymentAdjustmentCodeInterface, thunkAPI: any) => {
    try {
      const response: any = await addPaymentAdjustmentCodeAPIRequest(codeData);
      return response;
    } catch (error: any) {
      if (!error?.response) {
        throw error;
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const addPaymentAdjustmentCodeSlice = createSlice({
  name: 'addPaymentAdjustmentCode',
  initialState,
  reducers: {
    clearAddPaymentAdjustmentCodeResponse: (state) => {
      state.addPaymentAdjustmentCodeLoading = false;
      state.addPaymentAdjustmentCodeStatus = 'IDLE';
      state.addPaymentAdjustmentCodeData = {};
      state.addPaymentAdjustmentCodeError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addPaymentAdjustmentCodeRequest.pending, (state, action) => {
        state.addPaymentAdjustmentCodeLoading = true;
        state.addPaymentAdjustmentCodeStatus = 'PENDING';
      })
      .addCase(addPaymentAdjustmentCodeRequest.fulfilled, (state, action) => {
        state.addPaymentAdjustmentCodeData = action.payload;
        state.addPaymentAdjustmentCodeStatus = 'SUCCESS';
        state.addPaymentAdjustmentCodeLoading = false;
      })
      .addCase(addPaymentAdjustmentCodeRequest.rejected, (state, action) => {
        state.addPaymentAdjustmentCodeLoading = false;
        state.addPaymentAdjustmentCodeError = action.payload;
        state.addPaymentAdjustmentCodeStatus = 'FAILED';
      });
  },
});

export const { clearAddPaymentAdjustmentCodeResponse } = addPaymentAdjustmentCodeSlice.actions;

export const addPaymentAdjustmentCodeSelector = (state: RootState) => state.AddPaymentAdjustmentCode;

export default addPaymentAdjustmentCodeSlice.reducer;
